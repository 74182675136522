/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import AuthInput from '../../components/auth-input';
import { CircularProgress } from '@mui/material';
import PhoneNumber from '../../components/phone-number';
import AlertDialog from '../kyb/KybConfirmationModal';
import { useFormik } from 'formik';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../sections/auth/login/LoginForm.style';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';
import FileIcon from '../../assets/icons/file.svg';
import { getKybDetails, downloadFile, approveRejectKyb } from '../../api/kyb';
import { getCountriesList } from '../../api/adminManagement';
import {
  successToaster,
  errorToaster,
  getFormattedDate,
} from '../../utils/helperUtility';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { KybConstant } from './KybConstant';
import { Colors } from '../../common/constants/Color';
import config from '../../config';
import API_ENDPOINTS from '../../constants/apiEndpoints';

const FormTitle = styled('div')(({}) => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '20px',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const ViewFilesBtn = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.BLACK}`,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '4px, 22px, 4px, 22px',
  height: '25px',
  border: `1px solid ${Colors.LIGHT_YELLOW}`,
  borderRadius: '8px',
  width: '104px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const RejectButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.RED}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const FormContainer = styled('div')(({}) => ({
  marginTop: '35px',
  height: '100%',
  maxHeight: '480px',
  padding: '22px 32px 32px 32px',
  borderRadius: '14px',
  background: `${Colors.WHITE}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const FormButtons = styled('div')(({}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '28px',
  marginTop: '40px',
}));

export function KybVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [kybData, setKybData] = useState([]);
  const [ModalMessage, setModalMessage] = useState('');
  const [isActionRequired, setIsActionRequired] = useState(false);
  const [actionType, setActionType] = useState('');
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    ownerName: '',
    companyRegNo: '',
    organizationName: '',
    customerRemarks: '',
    status: '',
    requestedDate: '',
  });

  const { language } = useContext(LanguageContext);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();

  const kybId = () => {
    return query.get('id');
  };

  const handleClickOpen = (type) => {
    const msg =
      type === KybConstant.ACCEPT
        ? `${t('ACCEPT_CONFIRM_MSG')}`
        : `${t('REJECT_CONFIRM_MSG')}`;
    setModalMessage(msg);
    setActionType(
      type === KybConstant.ACCEPT ? KybConstant.ACCEPT : KybConstant.REJECT
    );
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleAccept = async (event) => {
    performKybVerification({
      status: event.action,
      customerRemarks: event.data || '',
    });
  };

  const handleReject = () => {
    setActionType('');
  };

  const performKybVerification = async ({ status, remarks }) => {
    try {
      setLoading(true);
      const body = {
        status,
        remarks,
      };
      const id = kybId();
      const response = await approveRejectKyb(body, id);
      if (response.data.isSuccess) {
        const messageKey =
          status === KybConstant.ACCEPT_STATUS
            ? t('KYC_ACCEPT_MSG')
            : t('KYC_REJECT_MSG');
        successToaster(messageKey, language);
        navigate(ENDPOINTS.KYB_MANAGEMENT);
      }
    } catch (err) {
      console.error('Error performing KYB verification:', err);
      setLoading(false);
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    }
  };

  const setFormData = (data) => {
    const {
      firstName,
      lastName,
      middleName,
      email,
      phoneNo,
      ownerName,
      companyRegNo,
      organizationName,
      customerRemarks,
      status,
      createdAt,
    } = data;

    setInitialValues({
      firstName,
      middleName,
      lastName,
      email,
      countryCode: data.fkCountriesId,
      phoneNumber: phoneNo,
      ownerName,
      companyRegNo,
      organizationName,
      customerRemarks,
      status,
      requestedDate: getFormattedDate(createdAt),
    });
  };

  useEffect(() => {
    const getDataForKyb = async () => {
      try {
        setLoading(true);
        const id = kybId();

        if (!id) {
          errorToaster(t('INVALID_ID'), language);
          navigate(ENDPOINTS.KYB_MANAGEMENT);
          return;
        }

        const resCountries = await getCountriesList();
        const modifiedCountry = resCountries.data.data.map((country) => ({
          ...country,
          id: country.id,
        }));

        setCountries(modifiedCountry);

        const response = await getKybDetails(id);
        const data = response.data.data;

        const isRequired = !(
          data.status === KybConstant.ACCEPT_STATUS ||
          data.status === KybConstant.REJECT_STATUS
        );

        setKybData(data);
        setFormData(data);
        setIsActionRequired(isRequired);
      } catch (err) {
        console.error('Error fetching KYB data:', err);
        errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
      } finally {
        setLoading(false);
      }
    };

    getDataForKyb();
  }, []);

  const openInNewTab = (id) => {
    const { API } = config;
    const BASE_URL = API.REACT_APP_BASE_URL;
    const url = `${BASE_URL}/public${API_ENDPOINTS.FILES}/${id}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleCancelButton = () => {
    navigate(ENDPOINTS.KYB_MANAGEMENT);
  };

  const handleAcceptReject = (type) => {
    handleClickOpen(type);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.KYB_MANAGEMENT)}
        >
          <img src={LeftChevronIcon} alt="back" />
        </div>
        <FormTitle>{t('KYB_VERIFICATION')}</FormTitle>
      </div>

      <FormContainer>
        <div className="row">
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="firstName"
            label={t('FIRST_NAME')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('FIRST_NAME')}
            name="firstName"
            id="firstName"
            value={formik.values.firstName}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="middleName"
            label={t('MIDDLE_NAME')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('MIDDLE_NAME')}
            name="middleName"
            id="middleName"
            value={formik.values.middleName}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="lastName"
            label={t('LAST_NAME')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('LAST_NAME')}
            name="lastName"
            id="lastName"
            value={formik.values.lastName}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="email"
            label={t('EMAIL_TEXT')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('EMAIL_TEXT')}
            name="email"
            id="email"
            value={formik.values.email}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="reqdate"
            label={t('REQUESTED_DATE')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('REQUESTED_DATE')}
            name="reqdate"
            id="reqdate"
            value={formik.values.requestedDate}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <PhoneNumber
            className="col-12 col-md-4"
            placeholderForCountry="Country code"
            fieldName="countryCode"
            multiSelect={false}
            labelName={t('PHONE')}
            options={countries}
            disabled={true}
            disableInput={true}
            selectedOptions={formik.values.countryCode}
            formik={formik}
            htmlFor="phoneNumber"
            label={''}
            inputProps={{
              sx: { ...textInputFieldStyle, height: '40px' },
              maxLength: 10,
            }}
            placeholder={t('PHONE')}
            name="phoneNumber"
            id="phoneNumber"
            value={formik.values.phoneNumber}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="ownerName"
            label={t('OWNER_NAME')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('OWNER_NAME')}
            name="ownerName"
            id="ownerName"
            value={formik.values.ownerName}
            FormHelperTextProps={{ sx: formHelperStyle }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="companyRegNo"
            label={t('COMPANY_REGISTRATION_NUMBER')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('COMPANY_REGISTRATION_NUMBER')}
            name="companyRegNo"
            id="companyRegNo"
            value={formik.values.companyRegNo}
            FormHelperTextProps={{ sx: formHelperStyle }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="organizationName"
            label={t('ORGANIZATION_NAME')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('ORGANIZATION_NAME')}
            name="organizationName"
            id="organizationName"
            value={formik.values.organizationName}
            FormHelperTextProps={{ sx: formHelperStyle }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="status"
            label={t('STATUS')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('STATUS')}
            name="status"
            id="status"
            value={formik.values.status}
            FormHelperTextProps={{ sx: formHelperStyle }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="remarks"
            label={t('REMARK')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('REMARK')}
            name="remarks"
            id="remarks"
            value={formik.values.customerRemarks}
            FormHelperTextProps={{ sx: formHelperStyle }}
          />

          <div className="col-12 col-md-6">
            <div className="">{t('Document Certificate')}</div>
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex justify-content-start align-items-center font-13px ms-4">
                <img src={FileIcon} alt="file" />
                <div className="ms-1">{kybData['fkCrDocId']}</div>
              </div>
              <ViewFilesBtn onClick={() => openInNewTab(kybData.fkCrDocId)}>
                {t('VIEW_FILE')}
              </ViewFilesBtn>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="">{t('Owner Pic Document')}</div>
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex justify-content-start align-items-center font-13px ms-4">
                <img src={FileIcon} alt="file" />
                <div className="ms-1">{kybData['fkOwnerPidDocId']}</div>
              </div>
              <ViewFilesBtn
                onClick={() => openInNewTab(kybData.fkOwnerPidDocId)}
              >
                {t('VIEW_FILE')}
              </ViewFilesBtn>
            </div>
          </div>
        </div>

        <FormButtons>
          <CancelButton onClick={handleCancelButton}>
            {isActionRequired ? t('CANCEL') : t('CLOSE')}
          </CancelButton>

          {isActionRequired && (
            <RejectButton
              onClick={() => handleAcceptReject(KybConstant.REJECT)}
            >
              {t('REJECT')}
            </RejectButton>
          )}

          {isActionRequired && (
            <SubmitButton
              type="submit"
              onClick={() => handleAcceptReject(KybConstant.ACCEPT)}
            >
              {t('ACCEPT')}
            </SubmitButton>
          )}
        </FormButtons>
      </FormContainer>

      {open && (
        <AlertDialog
          open={open}
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleReject={handleReject}
          actionType={actionType}
          title={t('KYB_VERIFICATION')}
          description={t(ModalMessage)}
        />
      )}
    </div>
  );
}

export default KybVerification;

// /* eslint-disable no-empty-pattern */
// /* eslint-disable no-unused-vars */
// import React, { useEffect, useState, useContext } from 'react';
// import { styled } from '@mui/material/styles';
// import Button from '@mui/material/Button';
// import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
// import ENDPOINTS from '../../constants/endpoints';
// import AuthInput from '../../components/auth-input';
// import PhoneNumber from '../../components/phone-number';
// import AlertDialog from '../kyb/KybConfirmationModal';
// import { useFormik } from 'formik';
// import {
//   textInputField,
//   textInputFieldStyle,
//   formHelperStyle,
// } from '../../sections/auth/login/LoginForm.style';
// import LeftChevronIcon from '../../assets/icons/left_chevron.svg';
// import FileIcon from '../../assets/icons/file.svg';
// import { getKybDetails, downloadFile, approveRejectKyb } from '../../api/kyb';
// import { getCountriesList } from '../../api/adminManagement';
// import {
//   successToaster,
//   errorToaster,
//   getFormattedDate,
// } from '../../utils/helperUtility';
// import { LanguageContext } from '../../globalContext/LanguageSwitcher';
// import { KybConstant } from './KybConstant';
// import { Colors } from '../../common/constants/Color';
// import config from '../../config';
// import API_ENDPOINTS from '../../constants/apiEndpoints';

// const FormTitle = styled('div')(({}) => {
//   return {
//     fontSize: '20px',
//     fontWeight: 700,
//     lineHeight: '20px',
//   };
// });

// const SubmitButton = styled(Button)(({ theme }) => ({
//   background: `${Colors.LIGHT_YELLOW}`,
//   color: `${Colors.BLACK}`,
//   fontSize: '14px',
//   fontWeight: 500,
//   lineHeight: '17px',
//   textAlign: 'center',
//   padding: '12px 32px 12px 32px',
//   height: '40px',
//   borderRadius: '8px',
//   '&:hover': {
//     background: `${Colors.LIGHT_YELLOW}`,
//   },
// }));

// const ViewFilesBtn = styled(Button)(({ theme }) => ({
//   background: `${Colors.GENERAL_GRAY}`,
//   color: `${Colors.BLACK}`,
//   fontSize: '12px',
//   fontWeight: 500,
//   lineHeight: '17px',
//   textAlign: 'center',
//   padding: '4px, 22px, 4px, 22px',
//   height: '25px',
//   border: `1px solid ${Colors.LIGHT_YELLOW}`,
//   borderRadius: '8px',
//   width: '104px',
//   '&:hover': {
//     background: `${Colors.GENERAL_GRAY}`,
//   },
// }));

// const CancelButton = styled(Button)(({ theme }) => ({
//   background: `${Colors.GENERAL_GRAY}`,
//   color: `${Colors.SLATE_GRAY}`,
//   fontSize: '14px',
//   fontWeight: 500,
//   lineHeight: '17px',
//   textAlign: 'center',
//   padding: '12px 32px 12px 32px',
//   height: '40px',
//   borderRadius: '8px',
//   '&:hover': {
//     background: `${Colors.GENERAL_GRAY}`,
//   },
// }));

// const RejectButton = styled(Button)(({ theme }) => ({
//   background: `${Colors.GENERAL_GRAY}`,
//   color: `${Colors.RED}`,
//   fontSize: '14px',
//   fontWeight: 500,
//   lineHeight: '17px',
//   textAlign: 'center',
//   padding: '12px 32px 12px 32px',
//   height: '40px',
//   borderRadius: '8px',
//   '&:hover': {
//     background: `${Colors.GENERAL_GRAY}`,
//   },
// }));
// const FormContainer = styled('div')(({}) => {
//   return {
//     marginTop: '35px',
//     height: '100%',
//     maxHeight: '480px',
//     padding: '22px 32px 32px 32px',
//     borderRadius: '14px',

//     background: `${Colors.WHITE}`,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//   };
// });

// const FormButtons = styled('div')(({}) => {
//   return {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     alignItems: 'center',
//     gap: '28px',
//     marginTop: '40px',
//   };
// });

// function KybVerification() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { t } = useTranslation();

//   const [loading, setLoading] = useState(false);
//   const [open, setOpen] = React.useState(false);
//   const [countries, setCountries] = useState([]);
//   const [kybData, setKybData] = useState([]);
//   const [ModalMessage, setModalMessage] = useState('');
//   const [isActionRequired, setIsActionRequired] = useState(false);
//   const [actionType, setActionType] = useState('');
//   const [initialValues, setInitialValues] = useState({
//     ownerName: '',
//     companyRegNo: '',
//     organizationName: '',
//     customerRemarks: '',
//     status: '',
//   });
//   const { language } = useContext(LanguageContext);

//   const formik = useFormik({
//     initialValues: initialValues,
//     enableReinitialize: true,
//   });

//   function useQuery() {
//     return new URLSearchParams(location.search);
//   }
//   const query = useQuery();

//   const kybId = () => {
//     return query.get('id');
//   };

//   const handleClickOpen = (type) => {
//     const msg =
//       type === KybConstant.ACCEPT
//         ? `${t('ACCEPT_CONFIRM_MSG')}`
//         : `${t('REJECT_CONFIRM_MSG')}`;
//     setModalMessage(msg);
//     setActionType(
//       type === KybConstant.ACCEPT ? KybConstant.ACCEPT : KybConstant.REJECT
//     );
//     setOpen(true);
//   };

//   const handleClose = (event, reason) => {
//     if (reason && reason === 'backdropClick') return;
//     setOpen(false);
//   };

//   const handleAccept = async (event) => {
//     performKybVerification({
//       status: event.action,
//       customerRemarks: event.data || '',
//     });
//   };

//   const handleReject = () => {
//     setActionType('');
//   };

//   const performKybVerification = async ({ status, remarks }) => {
//     try {
//       setLoading(false);
//       const body = {
//         status,
//         remarks,
//       };
//       const id = kybId();
//       const response = await approveRejectKyb(body, id);
//       if (response.data.isSuccess) {
//         const messageKey =
//           status === KybConstant.ACCEPT_STATUS
//             ? t('KYC_ACCEPT_MSG')
//             : t('KYC_REJECT_MSG');
//         successToaster(messageKey, language);
//         navigate(ENDPOINTS.KYB_MANAGEMENT);
//       }
//     } catch (err) {
//       console.log(err);
//       setLoading(false);
//       errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
//     }
//   };

//   useEffect(() => {
//     const id = kybId();
//     const getDataForKyb = async () => {
//       try {
//         setLoading(true);
//         const resCountries = await getCountriesList();

//         const modifiedCountry = resCountries.data.data.map((country) => {
//           return {
//             ...country,
//             id: country.id,
//           };
//         });
//         setCountries(modifiedCountry);
//         const response = await getKybDetails(id);
//         const data = response.data.data;
//         const isRequired =
//           data.status == KybConstant.ACCEPT_STATUS ||
//           data.status == KybConstant.REJECT_STATUS
//             ? false
//             : true;
//         setKybData(data);
//         setFormData(data);
//         setIsActionRequired(isRequired);

//         setLoading(false);
//       } catch (err) {
//         setLoading(false);
//         console.log(err);
//       }
//     };
//     getDataForKyb();
//   }, []);

//   const setFormData = (data) => {
//     const {
//       ownerName,
//       companyRegNo,
//       organizationName,
//       customerRemarks,
//       status,
//     } = data;
//     setInitialValues({
//       ownerName,
//       companyRegNo,
//       organizationName,
//       customerRemarks,
//       status,
//       requestedDate: getFormattedDate(data.createdAt),
//     });
//   };

//   const openInNewTab = (id) => {
//     const { API } = config;
//     const BASE_URL = API.REACT_APP_BASE_URL;
//     const url = `${BASE_URL}/public${API_ENDPOINTS.FILES}/${id}`;
//     window.open(url, '_blank', 'noopener,noreferrer');
//   };
//   const handleCancelButton = () => {
//     navigate(ENDPOINTS.KYB_MANAGEMENT);
//   };

//   const handleAcceptReject = (type) => {
//     handleClickOpen(type);
//   };

//   // return (
//   //   <div className="d-flex flex-column h-100">
//   //     <div className="d-flex align-items-center gap-2">
//   //       <div
//   //         className="cursor-pointer"
//   //         onClick={() => navigate(ENDPOINTS.KYB_MANAGEMENT)}
//   //       >
//   //         <img src={LeftChevronIcon} />
//   //       </div>
//   //       <FormTitle>{t('KYB_VERIFICATION')}</FormTitle>
//   //     </div>
//   //     <FormContainer>
//   //       <div className="row">
//   //         <AuthInput
//   //           className="col-12 col-md-4"
//   //           htmlFor="ownerName"
//   //           label={t('OWNER_NAME')}
//   //           disabled={true}
//   //           inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
//   //           placeholder={t('OWNER_NAME')}
//   //           name="ownerName"
//   //           id="ownerName"
//   //           value={formik.values.ownerName}
//   //           FormHelperTextProps={{
//   //             sx: formHelperStyle,
//   //           }}
//   //         />

//   //         <AuthInput
//   //           className="col-12 col-md-4"
//   //           htmlFor="companyRegNo"
//   //           label={t('COMPANY_REG_NO')}
//   //           disabled={true}
//   //           inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
//   //           placeholder={t('COMPANY_REG_NO')}
//   //           name="companyRegNo"
//   //           id="companyRegNo"
//   //           value={formik.values.companyRegNo}
//   //           FormHelperTextProps={{
//   //             sx: formHelperStyle,
//   //           }}
//   //         />

//   //         <AuthInput
//   //           className="col-12 col-md-4"
//   //           htmlFor="organizationName"
//   //           label={t('ORGANIZATION_NAME')}
//   //           disabled={true}
//   //           inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
//   //           placeholder={t('ORGANIZATION_NAME')}
//   //           name="organizationName"
//   //           id="organizationName"
//   //           value={formik.values.organizationName}
//   //           FormHelperTextProps={{
//   //             sx: formHelperStyle,
//   //           }}
//   //         />

//   //         <AuthInput
//   //           className="col-12 col-md-4"
//   //           htmlFor="status"
//   //           label={t('STATUS')}
//   //           disabled={true}
//   //           inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
//   //           placeholder={t('STATUS')}
//   //           name="status"
//   //           id="status"
//   //           value={formik.values.status}
//   //           FormHelperTextProps={{
//   //             sx: formHelperStyle,
//   //           }}
//   //         />

//   //         <AuthInput
//   //           className="col-12 col-md-4"
//   //           htmlFor="remarks"
//   //           label={t('REMARK')}
//   //           disabled={true}
//   //           inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
//   //           placeholder={t('REMARK')}
//   //           name="remarks"
//   //           id="remarks"
//   //           value={formik.values.customerRemarks}
//   //           FormHelperTextProps={{
//   //             sx: formHelperStyle,
//   //           }}
//   //         />
//   //         <div className="col-12 col-md-6">
//   //           <div className="">{t('Document Certificate')}</div>
//   //           <div className="d-flex justify-content-between mt-2">
//   //             <div className="d-flex justify-content-start align-items-center font-13px ms-4">
//   //               <img src={FileIcon} />
//   //               <div className="ms-1"> {`${kybData['fkCrDocId']}`}</div>
//   //             </div>
//   //             <ViewFilesBtn
//   //               onClick={() => {
//   //                 openInNewTab(kybData.fkCrDocId);
//   //               }}
//   //             >
//   //               {t('VIEW_FILE')}
//   //             </ViewFilesBtn>
//   //           </div>
//   //         </div>

//   //         <div className="col-12 col-md-6">
//   //           <div className="">{t('Owner Pic Document')}</div>
//   //           <div className="d-flex justify-content-between mt-2">
//   //             <div className="d-flex justify-content-start align-items-center font-13px ms-4">
//   //               <img className="" src={FileIcon} />
//   //               <div className="ms-1"> {`${kybData['fkOwnerPidDocId']}`}</div>
//   //             </div>
//   //             <ViewFilesBtn
//   //               onClick={() => {
//   //                 openInNewTab(kybData.fkOwnerPidDocId);
//   //               }}
//   //             >
//   //               {t('VIEW_FILE')}
//   //             </ViewFilesBtn>
//   //           </div>
//   //         </div>
//   //       </div>
//   //       <FormButtons>
//   //         <CancelButton onClick={() => handleCancelButton()}>
//   //           {isActionRequired ? t('CANCEL') : t('CLOSE')}
//   //         </CancelButton>

//   //         {isActionRequired && (
//   //           <RejectButton
//   //             onClick={() => handleAcceptReject(KybConstant.REJECT)}
//   //           >
//   //             {t('REJECT')}
//   //           </RejectButton>
//   //         )}

//   //         {isActionRequired && (
//   //           <SubmitButton
//   //             type="submit"
//   //             onClick={() => {
//   //               handleAcceptReject(KybConstant.ACCEPT);
//   //             }}
//   //           >
//   //             {t('ACCEPT')}
//   //           </SubmitButton>
//   //         )}
//   //       </FormButtons>
//   //     </FormContainer>
//   //     {open && (
//   //       <AlertDialog
//   //         open={open}
//   //         handleClose={handleClose}
//   //         handleAccept={handleAccept}
//   //         handleReject={handleReject}
//   //         actionType={actionType}
//   //         title={t('KYB_VERIFICATION')}
//   //         description={t(ModalMessage)}
//   //         ModalMessage
//   //       />
//   //     )}
//   //   </div>
//   // );
// }

// export { KybVerification };
