import { useMemo } from "react";

const useTableSearch = (data, searchQuery) => {
    const filteredData = useMemo(() => {
      if (!searchQuery) return data;
  
      const lowerCaseQuery = searchQuery.toLowerCase();
      return data.filter((row) =>
        Object.values(row).some((value) => {
          if (typeof value === 'string' && value.includes('@')) {
            // Validate email address
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(value) && value.toLowerCase().includes(lowerCaseQuery);
          }
          return value && value.toString().toLowerCase().includes(lowerCaseQuery);
        })
      );
    }, [data, searchQuery]);
  
    return filteredData;
  };
  

export default useTableSearch;