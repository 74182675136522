import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  cardsStyle,
  circularProgressBarPosition,
  circularProgressBarStyle,
  endFormStyles,
  formInputStyle,
  paperStyle,
} from './AddUser.style';
import { useFormik } from 'formik';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { addUserSchema } from '../../schema/index';
import AuthInput from '../../components/auth-input';
import { InputSaveButton } from './InputSaveButton';
import { SelectComponent } from './SelectComponent';
import {
  successToaster,
  errorToaster,
  getModulePerm,
  getFormattedDate,
  getFormattedName,
  returnName,
} from '../../utils/helperUtility';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from './../../sections/auth/login/LoginForm.style';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchParams } from 'react-router-dom';
import { getUser } from '../../api/getUser';
import { useAsync } from '../../hooks/useAsync';
import { InputButton } from './InputButton';
import { initial, pick } from 'lodash';
import { updateUser } from '../../api/updateUser';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import { useTranslation } from 'react-i18next';
import { MAX_INPUT_LENGTH, NA } from '../../common/constants/AppConstants';
import { Colors } from '../../common/constants/Color';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';
import ImageAvatar from '../ImageAvtar/ImageAvtar';
import { getCountries } from '../../api/getCountries';
import { getadminList } from '../../api/adminManagement';
import { role } from '../../constants/role-constant';

import CountrySelect from '../countrySelect/CountrySelect';
import { Stamp } from '../stamp/Stamp';
import Iconify from '../iconify/Iconify';
import { InformationBox } from './InformationBox';
import { allowedValuesForEdit, initialFormValues } from './formValues';

function preProcessData(data) {
  const processedData = {};
  for (const key in data) {
    if (data[key]) {
      processedData[key] = data[key];
    }
  }
  return processedData;
}

export const AddUserForm = ({ width, minHeight, viewOnly }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const submitForm = async (event) => {
    event.preventDefault();
    await formik.submitForm(formik.values);
  };
  let [searchParams] = useSearchParams();
  const { language } = useContext(LanguageContext);
  const [initialValues, setInitialValues] = useState(initialFormValues);
  const { loading, value: userProfile } = useAsync(() =>
    getUser(searchParams.get('id'))
  );
  const [accountManagerList, setAccountManagerList] = useState([]);
  const { loading: isLoadingCountries, value: getCountriesResponse } = useAsync(
    getCountries,
    []
  );
  const FilterAccountManager = (data) => {
    const filtereddata = data
      .filter((item) => item.isActive)
      .map((item) => ({
        id: item.id,
        name: returnName(item),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setAccountManagerList(filtereddata);
  };
  useEffect(() => {
    if (userProfile !== undefined) {
      const {
        data: { data },
      } = userProfile;
      getadminList({
        role: role.ACCOUNT_MANAGER,
        pageSkip: 0,
      })
        .then((response) => {
          FilterAccountManager(response.data.data);
          setInitialValues({
            currency: data?.currency ?? '',
            profileImageDocumentId: data?.profileImageDocumentId ?? null,
            bankDetails: data?.bankdetails ?? null,
            kycDetails: data?.kycDetails,
            countryId: data?.fkCountriesId ?? '',
            dateOfBirth: data?.dob
              ? getFormattedDate(data.dob, 'D MMM YYYY')
              : '',
            firstName: data.firstName ?? '',
            middleName: data.middleName ?? '',
            lastName: data.lastName ?? '',
            email: data.email ?? '',
            phoneNo: data.phoneNo ?? '',
            account_manager_id: data.accountManagerDetails
              ? data.accountManagerDetails['id']
              : '',
            status: data.isActive ? 'active' : 'inactive',
            gender: data.gender ?? '',
            zipCode: data.zipCode ?? '',
            address: data.address ?? '',
            city: data.city ?? '',
            locality: data.locality ?? '',
            name: getFormattedName(
              data.firstName,
              data.middleName,
              data.lastName
            ),
            walletBalance: data.walletBalance ?? NA,
            goldBalance: data.goldBalance ?? NA,
            silverBalance: data.silverBalance ?? NA,
            xpBalance: data.xpBalance ?? NA,
            xpPointsRedeemed: data.xpPointsRedeemed ?? NA,
            referalCode: data.referalCode ?? NA,
            referalCount: data.referalCount ?? NA,
            referredBy: data.referredBy ?? NA,
          });
        })
        .catch((err) => {});
    }
  }, [userProfile]);
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: async (submittedData) => {
      const response = await updateUser(
        userProfile.data.data.id,
        pick(
          {
            ...preProcessData(submittedData),
            isActive: submittedData.status === 'active',
            phoneNo: userProfile.data.data.phoneNo,
          },
          allowedValuesForEdit
        )
      );
      if (response.status === 200) {
        successToaster(t('USER_UPDATED_SUCCESS'), language);
        navigate(ENDPOINTS.USER_MANAGEMENT);
        return;
      }
      errorToaster(t('SOME_ERROR_OCCOURED'), language);
      navigate(ENDPOINTS.USER_MANAGEMENT);
    },
  });

  return (
    <Box
      component={'form'}
      width={width}
      onSubmit={submitForm}
      minHeight={minHeight}
      paddingX={'32px'}
      paddingTop={'22px'}
      paddingBottom={'130px'}
      sx={paperStyle}
    >
      {loading ? (
        <Box sx={circularProgressBarPosition}>
          <CircularProgress sx={circularProgressBarStyle} />
        </Box>
      ) : (
        <>
          <Box width="100%" height="80px" marginBottom="20px">
            <ImageAvatar
              firstName={initialValues.firstName}
              lastName={initialValues.lastName}
              imageId={initialValues.profileImageDocumentId}
            />
          </Box>
          <Box sx={formInputStyle}>
            <Box>
              <AuthInput
                htmlFor="firstName"
                label={t('FIRST_NAME')}
                required={true}
                sx={textInputField(false, language)}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: MAX_INPUT_LENGTH,
                }}
                placeholder={t('FIRST_NAME')}
                name="firstName"
                id="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
                textFieldError={false}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={false}
                disabled={viewOnly}
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="middleName"
                label={t('MIDDLE_NAME')}
                required={false}
                sx={textInputField(false, language)}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: MAX_INPUT_LENGTH,
                }}
                placeholder={t('MIDDLE_NAME')}
                name="middleName"
                id="middleName"
                onChange={formik.handleChange}
                value={formik.values.middleName}
                onBlur={formik.handleBlur}
                textFieldError={false}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={false}
                disabled={viewOnly}
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="lastName"
                label={t('LAST_NAME')}
                required={true}
                sx={textInputField(false, language)}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: MAX_INPUT_LENGTH,
                }}
                placeholder={t('LAST_NAME')}
                name="lastName"
                id="lastName"
                onClick={formik.handleChange}
                value={formik.values.lastName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                textFieldError={false}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={false}
                disabled={viewOnly}
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="dateOfBirth"
                label={t('Date of Birth')}
                sx={textInputField(false, language)}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: MAX_INPUT_LENGTH,
                }}
                placeholder={'Date of Birth'}
                name="dateOfBirth"
                id="dateOfBirth"
                onChange={formik.handleChange}
                value={formik.values.dateOfBirth}
                onBlur={formik.handleBlur}
                textFieldError={false}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                endAdornmentContent={
                  <Iconify
                    icon="oui:token-date"
                    width={25}
                    height={25}
                    sx={{ marginRight: '-6px' }}
                    color={Colors.COMMON_GRAY}
                  />
                }
                helperText={false}
                disabled={viewOnly}
              />
            </Box>
            <Box>
              <CountrySelect
                fieldName="contactPersonCountryCode"
                autoComplete="off"
                multiSelect={false}
                labelName={t('Country')}
                disabled={true}
                disableInput={true}
                options={
                  isLoadingCountries
                    ? []
                    : getCountriesResponse?.data?.data?.map(
                        ({ id, mobileCode, currency, nameEng }) => ({
                          id,
                          mobileCode,
                          currency,
                          nameEng,
                        })
                      )
                }
                selectedOptions={formik.values.countryId}
                formik={formik}
                errorText={formik.errors.countryId}
                htmlFor="phoneNumber"
                label={''}
                required={true}
                sx={textInputField(false, language)}
                height="40px"
                inputProps={{ sx: textInputFieldStyle, maxLength: 15 }}
                placeholder={t('Country')}
              />
            </Box>
            <Box>
              <SelectComponent
                required={true}
                fieldName={'gender'}
                formik={formik}
                labelName={t('Gender')}
                language={language}
                options={[
                  {
                    id: 'male',
                    name: t('Male'),
                  },
                  {
                    id: 'female',
                    name: t('Female'),
                  },
                ]}
                value={formik.values.gender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={true}
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="email"
                label={t('EMAIL_TEXT')}
                required={true}
                sx={textInputField(false, language)}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: MAX_INPUT_LENGTH,
                }}
                placeholder={t('EMAIL_TEXT')}
                name="email"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                textFieldError={false}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={false}
                disabled={viewOnly}
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="phoneNo"
                label={t('PHONE_NO')}
                required={true}
                sx={textInputField(false, language)}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: MAX_INPUT_LENGTH,
                }}
                placeholder={t('PHONE_NO')}
                name="phoneNo"
                id="phoneNo"
                onChange={formik.handleChange}
                value={`(+${userProfile.data.data.mobileCode}) ${formik.values.phoneNo}`}
                onBlur={formik.handleBlur}
                textFieldError={false}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={false}
                disabled={viewOnly}
              />
            </Box>
            <Box>
              <SelectComponent
                fieldName={'account_manager_id'}
                formik={formik}
                labelName={t('ACCOUNT_MANAGER')}
                placeholder={t('PLACEHOLDER_ACCOUNT_MANAGER_SELECT')}
                language={language}
                options={accountManagerList}
                value={formik.values.account_manager_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={viewOnly}
              />
            </Box>
            <Box>
              <InputButton
                fieldName={'status'}
                language={language}
                formik={formik}
                availableOptions={[
                  { key: 'active', display: t('ACTIVE'), value: 'active' },
                  {
                    key: 'inactive',
                    display: t('INACTIVE'),
                    value: 'inactive',
                  },
                ]}
                labelName={t('STATUS')}
                option={'active'}
                disabled={viewOnly}
              />
            </Box>
            <Box>
              <Stamp
                heading={t('KYC Status')}
                content={
                  <span
                    style={{
                      color:
                        initialValues?.kycDetails?.status === 'APPROVED'
                          ? Colors.GREEN_VERIFIED
                          : Colors.COMMON_GRAY,
                    }}
                  >
                    {t('Accepted')}
                  </span>
                }
                icon={
                  <Iconify
                    icon="icon-park-outline:check-one"
                    width={17}
                    height={17}
                    color={
                      initialValues?.kycDetails?.status === 'APPROVED'
                        ? Colors.GREEN_VERIFIED
                        : Colors.COMMON_GRAY
                    }
                  />
                }
              />
            </Box>
          </Box>
          <Box sx={cardsStyle}>
            <InformationBox
              title={t('Bank Details')}
              cardInformation={{
                [t('Name')]: initialValues?.bankDetails?.accountName || NA,
                [t('Account Number')]:
                  initialValues?.bankDetails?.accountNumber || NA,
                [t('Bank Name')]: initialValues?.bankDetails?.bankName || NA,
                [t('SWIFT Code')]: initialValues?.bankDetails?.swiftCode || NA,
                [t('Branch Address')]:
                  initialValues?.bankDetails?.bankAddress || NA,
                [t('IBAN Number')]: initialValues?.bankDetails?.iban || NA,
              }}
            />
            <InformationBox
              title={t('Address Details')}
              cardInformation={{
                [t('Zip Code')]: initialValues.zipCode || NA,
                [t('Locality/ Town')]: initialValues.locality || NA,
                [t('House No./ Street')]: initialValues.address || NA,
                [t('City')]: initialValues.city || NA,
              }}
            />
            <InformationBox
              title={t('Wallet Details')}
              singleColumn
              cardInformation={{
                [t(
                  'Money Wallet Balance'
                )]: `${initialValues.walletBalance} ${initialValues.currency}`,
                [t('Gold Wallet Balance')]: `${initialValues.goldBalance} gm`,
                [t(
                  'Silver Wallet Balance'
                )]: `${initialValues.silverBalance} gm`,
              }}
            />
            <InformationBox
              title={t('XP Reward')}
              singleColumn
              cardInformation={{
                [t('XP Rewards')]: `${initialValues.xpBalance}XP`,
                [t('Redeemed XP')]: `${initialValues.xpPointsRedeemed}XP`,
              }}
            />
            <InformationBox
              title={t('Referals')}
              singleColumn
              cardInformation={{
                [t('Referal Count')]: `${initialValues.referalCount}`,
                [t('Referal Code')]: `${initialValues.referalCode}`,
                [t('Referred By')]: `${initialValues.referredBy}`,
              }}
            />
          </Box>
          <Box sx={endFormStyles}>
            <InputSaveButton
              variant="contained"
              cancelButton
              onClick={() => navigate(ENDPOINTS.USER_MANAGEMENT)}
            >
              {t('CANCEL')}
            </InputSaveButton>
            <InputSaveButton
              variant="contained"
              display={!getModulePerm(MODULE_CONST.USER_MANAGEMENT)?.writeMode}
              disabled={
                viewOnly
                  ? !getModulePerm(MODULE_CONST.USER_MANAGEMENT)?.writeMode
                  : !(
                      formik.dirty &&
                      formik.isValid &&
                      getModulePerm(MODULE_CONST.USER_MANAGEMENT)?.writeMode
                    )
              }
              onClick={
                viewOnly
                  ? () =>
                      navigate(
                        `${ENDPOINTS.DASHBOARD}/${
                          ENDPOINTS.EDIT_USER
                        }?id=${searchParams.get('id')}`
                      )
                  : formik.submitForm
              }
            >
              {viewOnly ? t('EDIT') : t('SAVE')}
            </InputSaveButton>
          </Box>
        </>
      )}
    </Box>
  );
};
