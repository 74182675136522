import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { Card, Stack, Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ENDPOINTS from '../../constants/endpoints';
import moment from 'moment';
import { Colors } from '../../common/constants/Color';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import SearchBar from '../../components/search-bar-debounce';
import { getFormattedDate } from '../../utils/helperUtility';
import tradeService from '../../api/trades';
import { styled } from '@mui/material/styles';
import DateRangePicker from '../../components/date-range-picker';
import SelectInput from '../../components/select-input';

// Styled Components
const StyledFilterBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px',
  padding: theme.spacing(1),
  flexWrap: 'wrap',
}));

// Constants
const INITIAL_FILTER_STATE = {
  createdAt: [null, null],
  status: 'All',
  metal: 'All',
  source: 'All',
  clientId: undefined,
};

const ITEMS_PER_PAGE = 10;

export default function TradeOrder() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // State
  const [searchTerm, setSearchTerm] = useState('');
  const [tradeList, setTradeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalList, setTotalList] = useState(0);
  const [filterData, setFilterData] = useState(INITIAL_FILTER_STATE);

  // Update the ButtonsForTable component
  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={Colors.GRAY}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => goToViewDetails(rowItemData)}
          >
            {t('VIEW')}
          </Typography>
        </Box>
      </>
    );
  };

  const goToViewDetails = async (row) => {
    try {
      let apiResponse;

      // Call the position API if orderNo doesn't exist
      apiResponse = await tradeService.getOrderById(row.id);
      console.log('API RESP:', apiResponse);

      if (apiResponse) {
        // Assuming you have the response and you want to navigate after getting the data
        navigate(`${ENDPOINTS.TRADE_ORDER_VIEW}?id=${row.id}`);
      }
    } catch (error) {
      console.error('Error fetching trade details:', error);
    }
  };

  // Options for select inputs
  const statusList = [
    { label: t('All'), value: 'All' },
    { label: t('success'), value: 'SUCCESS' },
    { label: t('Pending'), value: 'PENDING' },
    { label: t('cancelled'), value: 'CANCELLED' },
    { label: t('Failed'), value: 'FAILED' },
  ];

  const metalList = [
    { label: t('All'), value: 'All' },
    { label: t('Gold'), value: 'GOLD' },
    { label: t('Silver'), value: 'SILVER' },
  ];

  const sourceList = [
    { label: t('All'), value: 'All' },
    { label: t('GOLD_BUY'), value: 'gold_buy' },
    { label: t('SILVER_BUY'), value: 'silver_buy' },
    { label: t('SILVER_SELL'), value: 'silver_sell' },
    { label: t('GOLD_SELL'), value: 'gold_sell' },
    { label: t('WALLET_RECHARGE'), value: 'wallet_recharge' },
    { label: t('WITHDRAW'), value: 'withdraw' },
  ];

  const TransactionColumns = [
    // { key: 'symbol.symbolName', title: t('Symbol') },
    // {
    //   key: 'createdAt',
    //   title: t('Created At'),
    //   render: (rowData) => new Date(rowData.createdAt).toLocaleString(),
    // },
    // {
    //   key: 'closedAt',
    //   title: t('Closed At'),
    //   render: (rowData) => new Date(rowData.closedAt).toLocaleString(),
    // },
    { key: 'orderNo', title: t('Order No') },
    { key: 'buyer_detail', title: t('Buyer details') },

    { key: 'mode', title: t('Mode') },
    { key: 'status', title: t('Status') },
    { key: 'weight', title: t('Volume') },
    { key: 'bidPrice', title: t('Bid Price') },
    // { key: 'unitOpeningRate', title: t('Opening Rate') },
    // { key: 'unitClosingRate', title: t('Closing Rate') },
    // { key: 'totalOpeningPrice', title: t('Opening Price') },
    // { key: 'totalClosingPrice', title: t('Closing Price') },
    // { key: 'profitAmount', title: t('Profit') },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  // Handlers
  const handleFilterSelection = (key, event) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: event,
    }));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getData(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Query builder
  const getListQueryPayload = useCallback(() => {
    const dateFilterData = {};
    const { status, source, metal, createdAt, clientId } = filterData;

    if (status && status !== 'All') dateFilterData.status = status;
    if (source && source !== 'All') dateFilterData.source = source;
    if (metal && metal !== 'All') dateFilterData.metal = metal;
    if (searchTerm) dateFilterData.keyword = searchTerm;
    if (clientId && clientId !== 'All') dateFilterData.fk_clients_id = clientId;

    if (createdAt?.length === 2 && moment(createdAt[1]).isValid()) {
      dateFilterData.from = getFormattedDate(createdAt[0], 'YYYY-MM-DD');
      const endDateWithExtraDay = moment(createdAt[1]).add(1, 'days');
      dateFilterData.to = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }

    return dateFilterData;
  }, [filterData, searchTerm]);

  // Data fetching
  const getData = useCallback(
    async (page = currentPage) => {
      try {
        setLoading(true);
        const query = {
          pageLimit: ITEMS_PER_PAGE,
          pageSkip: (page - 1) * ITEMS_PER_PAGE,
          ...getListQueryPayload(),
        };

        const [responseOrderClose, responseOrderOpen] = await Promise.all([
          tradeService.getTradeOrderClose(query),
          tradeService.getTradeOrderOpen(query),
        ]);

        const closeOrder = responseOrderClose?.data?.data || [];
        const openOrder = responseOrderOpen?.data?.data || [];

        const combinedList = [...closeOrder, ...openOrder];

        // Sort the combined list by 'createdAt' in descending order
        const sortedCombinedList = combinedList.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        // Update the state with the sorted combined list and total count
        setTradeList(sortedCombinedList);

        console.log('Combined List:', sortedCombinedList); // Debug log
        setTotalList(
          (responseOrderClose?.data?.total || 0) +
            (responseOrderOpen?.data?.total || 0)
        );
        setCurrentPage(page);
      } catch (error) {
        console.error('Error fetching trade list:', error);
      } finally {
        setLoading(false);
      }
    },
    [currentPage, getListQueryPayload]
  );

  // const transformedData =
  //   tradeList?.map((item) => {
  //     return {
  //       ...item,
  //       id: item.id,
  //       mode: item.mode || 'NA',
  //       renderAmount: () => {
  //         return (
  //           <div className="d-flex gap-1">
  //             <div>{item.amount}</div>
  //             <div>{`${item.currency || ''}`}</div>
  //           </div>
  //         );
  //       },
  //       buyer_detail:
  //         item.firstName || item.middleName || item.lastName
  //           ? `${item.firstName || ''} ${item.middleName || ''} ${
  //               item.lastName || ''
  //             }`.trim()
  //           : 'NA',
  //       createdAt: getFormattedDate(item.createdAt, 'DD/MM/YYYY HH:mm:ss'),
  //       renderButton: () => <ButtonsForTable rowItemData={item} />,
  //       symbolName: item.symbol?.symbolName || 'NA',
  //       status: item.status || 'NA',
  //       unitOpeningRate: item.unitOpeningRate || 'NA',
  //       unitClosingRate: item.unitClosingRate || 'NA',
  //       totalOpeningPrice: item.totalOpeningPrice || 'NA',
  //       totalClosingPrice: item.totalClosingPrice || 'NA',
  //       profitAmount: item.profitAmount || 'NA',
  //       positionNo: item.positionNo || 'NA',
  //     };
  //   }) || [];
  const transformedData = tradeList?.map((item) => {
    // Order response transformation
    return {
      ...item,
      id: item,
      mode: item.mode || 'NA',
      renderAmount: () => (
        <div className="d-flex gap-1">
          <div>{item.bidPrice}</div>
          <div>{`${item.currency || ''}`}</div>
        </div>
      ),
      status: item.status,
      buyer_detail:
        item.firstName || item.middleName || item.lastName
          ? `${item.firstName || ''} ${item.middleName || ''} ${
              item.lastName || ''
            }`.trim()
          : 'NA',
      createdAt: getFormattedDate(item.createdAt, 'DD/MM/YYYY HH:mm:ss'),
      renderButton: () => <ButtonsForTable rowItemData={item} />,
    };
  });

  // Effects
  useEffect(() => {
    getData();
  }, [getData]);

  const pageCount = Math.ceil(totalList / ITEMS_PER_PAGE);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Typography variant="h4" gutterBottom>
          {t('TRADE_ORDER')}
        </Typography>
      </Stack>

      <Card>
        <StyledFilterBox>
          <SelectInput
            placeholder={t('STATUS')}
            labelInline
            containerSx={{ width: '120px' }}
            label={t('STATUS')}
            value={filterData.status}
            options={statusList}
            handleSelectChange={(event) =>
              handleFilterSelection('status', event)
            }
          />
          <SelectInput
            placeholder={t('METAL')}
            labelInline
            containerSx={{ width: '120px' }}
            label={t('METAL')}
            value={filterData.metal}
            options={metalList}
            handleSelectChange={(event) =>
              handleFilterSelection('metal', event)
            }
          />
          <SelectInput
            placeholder={t('Source')}
            labelInline
            containerSx={{ width: '120px' }}
            label={t('SOURCE')}
            value={filterData.source}
            options={sourceList}
            handleSelectChange={(event) =>
              handleFilterSelection('source', event)
            }
          />
          <SelectInput
            labelInline
            containerSx={{ width: '120px' }}
            value={filterData.clientId}
            handleSelectChange={(value) =>
              handleFilterSelection('clientId', value)
            }
          />
          <DateRangePicker
            placeholder={t('DATE_PICKER_LABEL')}
            dateRange={filterData.createdAt}
            setDateRange={(event) => handleFilterSelection('createdAt', event)}
          />
          <SearchBar
            onChange={handleSearchChange}
            value={searchTerm}
            placeholder={t('SEARCH_TRANSACTION_PLACEHOLDER')}
            maxlength={50}
          />
        </StyledFilterBox>

        <Scrollbar>
          {/* In your CommonTable usage */}
          <CommonTable
            resourceName={{ singular: 'trade', plural: 'trades' }}
            columns={TransactionColumns}
            data={transformedData}
            loading={loading}
            isCheckBox={false}
            showPagination
            pageCount={pageCount}
            currentPage={currentPage}
            emptyTableText={t('NO_TRANSACTION_FOUND')}
            pagination={{
              count: pageCount,
              page: currentPage,
              onChange: handlePageChange,
            }}
            // renderButton={ButtonsForTable} // Add this prop
            totalList={totalList}
            itemsPerPage={ITEMS_PER_PAGE}
          />
        </Scrollbar>
      </Card>
    </Container>
  );
}
