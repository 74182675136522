import config from './../../config';
import ENDPOINTS from '../../constants/endpoints';
import { MODULE_CONST } from './ModuleConstant';
import { useTranslation } from 'react-i18next';
export * from './Regex';
export * from './Secrets';
export * from './Color';
export * from './Language';

export const KENYA_REGIONS = [
  'Nairobi',
  'Central',
  'Coast',
  'Eastern',
  'North Eastern',
  'Nyanza',
  'Rift Valley',
  'Western',
];

export const VENDOR_STAGES = {
  DOCUMENT_VERIFICATION: {
    index: 0,
    value: 'details-and-documents',
  },
  CONTRACT_VERIFICATION: {
    index: 1,
    value: 'contracts',
  },
  SHOP_DETAILS: {
    index: 2,
    value: 'shop-details',
  },
  TERMINAL_DETAILS: {
    index: 4,
    value: 'terminal-details',
  },
  ACKNOWLEGEMENT_VERIFICATION: {
    index: 5,
    value: 'acknowlegement-letter',
  },
  FEES_DETAILS: {
    index: 6,
    value: 'fees-details',
  },
  FEES_PAYMENT: {
    index: 7,
    value: 'fees-payment',
  },
  GO_LIVE: {
    index: 8,
    value: 'going-live',
  },
  ONBOARDED: {
    index: 9,
    value: 'going-live',
  },
};

export const MAX_FILE_SIZE = 5000000;

export const IMG_AND_PDF_REGEX = /(image\/\w+)|(application\/pdf)/;

export const initialQuery = {
  page: 1,
  limit: 10,
};

export const VENDOR_ROLES = {
  ADMIN: 'ADMIN',
  SUPERVISOR: 'SUPERVISOR',
  OPERATOR: 'OPERATOR',
};

export const OTP_TIME = 600;

export const MODULES = {
  DASHBOARD: 'DASHBOARD',
  FILE: 'FILE',
  ONBOARDING: 'ONBOARDING',
  USER: 'USER',
  STATIC_DATA: 'STATIC_DATA',
  SHOPS: 'SHOPS',
};

export const APP_LANGUAGE = {
  ENGLISH: 'en',
  ARABIC: 'ar',
};

export const SESSION_LANGUAGE_KEY = 'lang';
export const SELECTED_SIDE_BAR_MODULE = 'selectedSidebarModule';

export const OTP_LENGTH = 4;
export const ENGLISH = 'English';
export const ARABIC = 'عربي';

export const SOME_ERROR_OCCOURED = 'Some Error Occoured';

export const SEMI_MAX_INPUT_LENGTH = 25;
export const MAX_INPUT_LENGTH = 50;
export const MAX_LONG_TEXT_INPUT_LENGTH = 300;
export const flag = {
  EGP: 'egypt.jpeg',
  AED: 'unitedArab.png',
  SAR: 'saudi.png',
};

export const ALL = 'All';

export const USER_DETAILS_KEY_NAME = 'userDetails';

export const BEFORE_LOGIN_URL = 'beforeLoginUrl';

export const SELECT_ROLE_LIMIT = 5;

export const COMMONLY_ACCESSIBLE_URLS = [];

export const NA = 'N/A';

export const ASSETS_IMAGE_PATH = `${config.API.REACT_APP_BASE_URL}/public/files`;

export const AccountManagerPermission = [
  {
    id: '8671d85a-d087-11ee-9e49-02012f70c635',
    moduleName: 'User Management',
    moduleCode: 'USER_MANAGEMENT',
    readMode: true,
    writeMode: true,
  },
  {
    id: '8671d9c2-d087-11ee-9e4e-02012f70c635',
    moduleName: 'Trade History',
    moduleCode: 'TRADE_LIST',
    readMode: true,
    writeMode: true,
  },
  {
    id: '8671d9c2-d087-11ee-9e4e-02012f70c635',
    moduleName: 'Trade Order History',
    moduleCode: 'TRADE_ORDER',
    readMode: true,
    writeMode: true,
  },
  {
    id: '8671d8a0-d087-11ee-9e4a-02012f70c635',
    moduleName: 'KYC Verification and Approval',
    moduleCode: 'VERIFICATION_APPROVAL',
    readMode: true,
    writeMode: true,
  },
  {
    id: '8671d8a0-d087-11ee-9e4a-02012f70c635',
    moduleName: 'KYB Verification and Approval',
    moduleCode: 'VERIFICATION_APPROVAL_KYB',
    readMode: true,
    writeMode: true,
  },
  {
    id: '7632d43e-daec-11ee-a6c4-02012f70c635',
    moduleName: 'Order Management',
    moduleCode: 'ORDER_MANAGEMENT',
    readMode: true,
    writeMode: true,
  },

  {
    id: '8671d9fe-d087-11ee-9e4f-02012f70c635',
    moduleName: 'Reporting and Analytics',
    moduleCode: 'REPORTING_ANALYTICS',
    readMode: false,
    writeMode: false,
  },

  {
    id: '8671d9c2-d087-11ee-9e4e-02012f70c635',
    moduleName: 'Transaction History',
    moduleCode: 'TRANSACTION_HISTORY',
    readMode: true,
    writeMode: true,
  },
  {
    id: '624ec00c-d6de-11ee-9d8f-02012f70c635',
    moduleName: 'Transfer Management',
    moduleCode: 'TRANSFER_MANAGEMENT',
    readMode: true,
    writeMode: true,
  },
  {
    id: '725cb848-0d00-11ef-97ad-02692eef7a97',
    moduleName: 'Support Ticket Management',
    moduleCode: 'TICKET_MANAGEMENT',
    readMode: true,
    writeMode: true,
  },

  {
    id: '98f7245e-22f7-11ef-90d7-02692eef7a97',
    moduleName: 'Bank Transfers',
    moduleCode: 'BANK_TRANSFERS',
    readMode: true,
    writeMode: true,
  },
  {
    id: '993627da-22f7-11ef-90d8-02692eef7a97',
    moduleName: 'Withdrawal Requests',
    moduleCode: 'WITHDRAWALS',
    readMode: true,
    writeMode: true,
  },
];

export const routeMapping = [
  {
    moduleCode: 'HOME',
    route: ENDPOINTS.HOME,
  },
  {
    moduleCode: 'ADMIN_MANAGEMENT',
    route: ENDPOINTS.ADMIN_MANAGEMENT,
  },
  {
    moduleCode: 'ACCOUNT_MANAGER',
    route: ENDPOINTS.ACCOUNT_MANAGEMENT,
  },
  {
    moduleCode: 'USER_MANAGEMENT',
    route: ENDPOINTS.USER_MANAGEMENT,
  },

  {
    moduleCode: 'ROLE_MANAGEMENT',
    route: ENDPOINTS.ROLE_MANAGEMENT,
  },
  {
    moduleCode: 'TRANSACTION_HISTORY',
    route: ENDPOINTS.TRANSACTION_MANAGEMENT,
  },
  {
    route: ENDPOINTS.KYC_MANAGEMENT,
    moduleCode: 'VERIFICATION_APPROVAL',
  },
  {
    route: ENDPOINTS.KYB_MANAGEMENT,
    moduleCode: 'VERIFICATION_APPROVAL_KYB',
  },
  {
    route: ENDPOINTS.PRODUCT_MANAGEMENT,

    moduleCode: 'PRODUCT_MANAGEMENT',
  },
  {
    route: ENDPOINTS.ORDER_MANAGEMENT,

    moduleCode: 'ORDER_MANAGEMENT',
  },
  {
    route: ENDPOINTS.WAREHOUSE_MANAGEMENT,

    moduleCode: 'WAREHOUSE_MANAGEMENT',
  },
  {
    route: ENDPOINTS.REPORT_MANAGEMENT,
    moduleCode: 'REPORTING_ANALYTICS',
  },
  {
    route: ENDPOINTS.TRANSFER_LIST,
    moduleCode: MODULE_CONST.TRANSFER_MANAGEMENT,
  },

  {
    route: ENDPOINTS.ANNOUNCEMENT_LIST,
    moduleCode: MODULE_CONST.ANNOUNCEMENT_MANAGEMENT,
  },
  {
    route: ENDPOINTS.TICKET_MANAGEMENT,
    moduleCode: MODULE_CONST.TICKET_MANAGEMENT,
  },
  {
    route: ENDPOINTS.BANK_TRANSFERS,
    moduleCode: MODULE_CONST.BANK_TRANSFERS,
  },
  {
    route: ENDPOINTS.CITY_MANAGEMENT,
    moduleCode: MODULE_CONST.CITY_MANAGEMENT,
  },
  {
    route: ENDPOINTS.TRADE_LIST,
    moduleCode: MODULE_CONST.TRADE_LIST,
  },
  {
    route: ENDPOINTS.TRADE_ORDER,
    moduleCode: MODULE_CONST.TRADE_ORDER,
  },
];

export function useStatusList() {
  const { t } = useTranslation();
  return [
    { label: t('All'), value: '' },
    { label: t('Pending'), value: 'PENDING' },
    { label: t('Rejected'), value: 'REJECTED' },
    { label: t('Approved'), value: 'APPROVED' },
  ];
}

export const METAL_TYPE_OPTIONS = [
  {
    id: 1,
    label: 'All',
    value: ALL,
  },
  {
    id: 2,
    label: 'Gold',
    value: 'GOLD',
  },
  {
    id: 3,
    label: 'Silver',
    value: 'SILVER',
  },
];

export const STATUS_TYPE_OPTIONS = [
  {
    id: 1,
    label: 'ACTIVE',
    value: true,
  },
  {
    id: 2,
    label: 'INACTIVE',
    value: false,
  },
];

export const UNIT_OPTIONS = [
  {
    id: 1,
    label: 'PERCENTAGE',
    value: `PERCENTAGE`,
  },
  {
    id: 2,
    label: 'GRAM',
    value: `GRAM`,
  },
];

export const UNIT_LABELS = {
  PERCENTAGE: 'PERCENTAGE',
  GRAM: 'GRAM',
};

export const METAL_LABELS = {
  GOLD: 'GOLD',
  SILVER: 'SILVER',
};

export const ACTION_LABELS = {
  ADD_NEW: 'ADD',
  UPDATE_EXISTING: 'UPDATE',
  DELECT_EXISTING: 'DELECT',
};

export const tokenList = [
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
];

export const uniqueKeyGenerator = (length, tokens) => {
  var key = '';
  for (let i = 0; i < length; i++)
    key += tokens[Math.floor(Math.random() * (tokens.length - 1))];
  return key;
};
