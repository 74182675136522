/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useCallback } from 'react';
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import SearchBar from '../../components/search-bar-debounce';
import { useTranslation } from 'react-i18next';
import ENDPOINTS from '../../constants/endpoints';
import { getKybList } from '../../redux/slices/kybList.slice';
import { getFormattedDate, downloadExcelFile } from '../../utils/helperUtility';
import DateRangePicker from '../../components/date-range-picker';
import SelectInput from '../../components/select-input';
import moment from 'moment';
import { getKybList as getKybListApi } from '../../api/kyb';
import { Upload, CirclePlus } from 'lucide-react';

const resourceName = {
  singular: 'order',
  plural: 'orders',
};

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#E9D888',
  color: '#333333',
  padding: '8px 16px',
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '16px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#d4c578',
    boxShadow: 'none',
  },
}));

const StyledFilterBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px',
  padding: '8px',
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function KybManagement() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({
    createdAt: [null, null],
    status: 'All',
  });
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [uploadData, setUploadData] = useState({
    ownerName: '',
    companyRegNo: '',
    organizationName: '',
    documents: null,
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: kybListData = [],
    loading = false,
    totalList = 0,
    limit = 10,
  } = useSelector((state) => state.kybList || {});

  const itemsPerPage = parseInt(limit);
  const pageCount = Math.ceil(totalList / itemsPerPage);

  const kybColumns = [
    { key: 'ownerName', title: t('OWNER_NAME') },
    { key: 'companyRegNo', title: t('COMPANY_REGISTRATION_NUMBER') },
    { key: 'organizationName', title: t('ORGANIZATION_NAME') },
    { key: 'status', title: t('STATUS') },
    { key: 'createdAt', title: t('REQUESTED_DATE') },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const statusList = [
    { label: t('All'), value: 'All' },
    { label: t('Pending'), value: 'PENDING' },
    { label: t('Approved'), value: 'APPROVED' },
    { label: t('Rejected'), value: 'REJECTED' },
  ];

  const handleUploadModalOpen = () => setOpenUploadModal(true);

  const handleUploadModalClose = () => {
    setOpenUploadModal(false);
    setUploadData({
      ownerName: '',
      companyRegNo: '',
      organizationName: '',
      documents: null,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUploadData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setUploadData((prev) => ({
      ...prev,
      documents: e.target.files,
    }));
  };

  const handleManualUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('ownerName', uploadData.ownerName);
      formData.append('companyRegNo', uploadData.companyRegNo);
      formData.append('organizationName', uploadData.organizationName);

      if (uploadData.documents) {
        Array.from(uploadData.documents).forEach((file, index) => {
          formData.append(`documents`, file);
        });
      }

      handleUploadModalClose();
      getKybListFn(currentPage);
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const getListQueryPayload = useCallback(() => {
    const dateFilterData = {};
    if (filterData.status && filterData.status !== 'All') {
      dateFilterData.status = filterData.status;
    }
    if (searchTerm) {
      dateFilterData.searchStr = searchTerm;
    }
    if (
      filterData.createdAt?.length === 2 &&
      moment(filterData.createdAt[1]).isValid()
    ) {
      dateFilterData.startDate = getFormattedDate(
        filterData.createdAt[0],
        'YYYY-MM-DD'
      );
      const endDateWithExtraDay = moment(filterData.createdAt[1]).add(
        1,
        'days'
      );
      dateFilterData.endDate = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }
    return dateFilterData;
  }, [filterData, searchTerm]);

  const getKybListFn = useCallback(
    async (page = 1) => {
      try {
        const queryPayload = {
          ...getListQueryPayload(),
          pageLimit: itemsPerPage,
          pageSkip: page - 1,
        };
        const response = await dispatch(
          getKybList({ reqData: queryPayload })
        ).unwrap();
      } catch (error) {
        console.error('Error fetching KYB list:', error);
      }
    },
    [dispatch, getListQueryPayload, itemsPerPage]
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getKybListFn(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleFilterSelection = (key, event) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: event,
    }));
  };

  const goToKybVerification = (item) => {
    navigate(`${ENDPOINTS.KYB_VERIFICATION}?id=${item.id}`);
  };

  const ButtonsForTable = ({ rowItemData }) => (
    <Box display="flex" gap="40px" justifyContent="center">
      <Typography
        fontWeight="500"
        fontSize="12px"
        fontFamily="Inter"
        color="#A5A4A2"
        display="flex"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={() => goToKybVerification(rowItemData)}
      >
        {t('VIEW')}
      </Typography>
    </Box>
  );

  const transformedData =
    kybListData?.map((kybItem) => ({
      id: kybItem.id,
      fkUsersId: kybItem.fkUsersId,
      fkCRDocId: kybItem.fkCRDocId,
      fkOwnerPidDocId: kybItem.fkOwnerPidDocId,
      organizationName: kybItem.organizationName,
      companyRegNo: kybItem.companyRegNo,
      ownerName: kybItem.ownerName,
      status: kybItem.status,
      createdAt: getFormattedDate(kybItem.createdAt, 'DD/MM/YYYY HH:mm:ss'),
      renderButton: () => <ButtonsForTable rowItemData={kybItem} />,
    })) || [];

  useEffect(() => {
    getKybListFn(currentPage);
  }, [getKybListFn, currentPage, filterData, searchTerm]);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Typography variant="h4" gutterBottom>
          {t('KYB_VERIFICATION')}
        </Typography>
        {/* <StyledButton
          startIcon={<CirclePlus size={20} />}
          onClick={handleUploadModalOpen}
        >
          Manual KYB
        </StyledButton> */}
      </Stack>

      <Modal
        open={openUploadModal}
        onClose={handleUploadModalClose}
        aria-labelledby="manual-upload-modal"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" mb={3}>
            {t('MANUAL_KYB_UPLOAD')}
          </Typography>

          <Stack spacing={3}>
            <TextField
              fullWidth
              label={t('OWNER_NAME')}
              name="ownerName"
              value={uploadData.ownerName}
              onChange={handleInputChange}
            />

            <TextField
              fullWidth
              label={t('COMPANY_REGISTRATION_NUMBER')}
              name="companyRegNo"
              value={uploadData.companyRegNo}
              onChange={handleInputChange}
            />

            <TextField
              fullWidth
              label={t('ORGANIZATION_NAME')}
              name="organizationName"
              value={uploadData.organizationName}
              onChange={handleInputChange}
            />

            <Box>
              <input
                accept="image/*,.pdf,.doc,.docx"
                style={{ display: 'none' }}
                id="upload-documents"
                type="file"
                multiple
                onChange={handleFileChange}
              />
              <label htmlFor="upload-documents">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<Upload size={20} />}
                  fullWidth
                >
                  {t('UPLOAD_DOCUMENTS')}
                </Button>
              </label>
              {uploadData.documents && (
                <Typography variant="caption" display="block" mt={1}>
                  {uploadData.documents.length} {t('FILES_SELECTED')}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'flex-end',
                mt: 2,
              }}
            >
              <Button variant="outlined" onClick={handleUploadModalClose}>
                {t('CANCEL')}
              </Button>
              <Button
                variant="contained"
                onClick={handleManualUpload}
                disabled={
                  !uploadData.ownerName ||
                  !uploadData.companyRegNo ||
                  !uploadData.organizationName
                }
              >
                {t('UPLOAD')}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>

      <Card>
        <Scrollbar>
          <Card>
            <StyledFilterBox>
              <div className="w-50 d-flex justify-content-start">
                <SelectInput
                  placeholder="Status"
                  labelInline={true}
                  containerSx={{ width: '120px' }}
                  label="Status"
                  value={filterData.status}
                  options={statusList}
                  handleSelectChange={(event) =>
                    handleFilterSelection('status', event)
                  }
                />
                <div className="ms-2">
                  <DateRangePicker
                    placeholder={t('REQUESTED_DATE')}
                    dateRange={filterData.createdAt}
                    setDateRange={(event) =>
                      handleFilterSelection('createdAt', event)
                    }
                  />
                </div>
              </div>
              <SearchBar
                onChange={handleSearchChange}
                value={searchTerm}
                onClear={handleClearSearch}
                placeholder={t('Search')}
                maxlength={50}
              />
            </StyledFilterBox>
            <CommonTable
              resourceName={resourceName}
              data={transformedData}
              columns={kybColumns}
              isCheckBox={false}
              loading={loading}
              showPagination={true}
              pageCount={pageCount}
              currentPage={currentPage}
              emptyTableText={t('NO_KYB_FOUND')}
              handlePageChange={handlePageChange}
              totalList={totalList}
              itemsPerPage={itemsPerPage}
            />
          </Card>
        </Scrollbar>
      </Card>
    </Container>
  );
}
