import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import ENDPOINTS from '../../constants/endpoints';
import { NA } from '../../common/constants/AppConstants';

import {
  CancelButton,
  cardsStyle,
  circularProgressBarPosition,
  circularProgressBarStyle,
  endFormStyles,
  heading,
  paperStyle,
  RejectButton,
  SubmitButton,
} from './WithdrawalDetails.style';
import { InformationBox } from '../../components/add-user/InformationBox';
import { Header } from '../../components/add-user/Header';
import { withdrawalConstants } from './constants';
import {
  acceptRejectWithdrawalDetails,
  getWithdrawalListData,
} from '../../api/withdrawalRequest';
import AlertDialog from './WithdrawalConfirmationModal';

const WithdrawalDetails = ({ width, minHeight }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ModalMessage, setModalMessage] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [isShowModal, setIsShowModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState([]);

  const getURLQueryString = () => {
    return new URLSearchParams(location?.search);
  };
  const queryString = getURLQueryString();
  const withdrawalId = queryString.get('id');

  const getCustomerName = () => {
    const fullName = [
      withdrawalData?.firstName,
      withdrawalData?.middleName,
      withdrawalData?.lastName,
    ]
      .filter(Boolean)
      .join(' ');
    return `${fullName || ''}`;
  };

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await getWithdrawalListData({
        id: withdrawalId,
      });
      setWithdrawalData(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tickets data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    if (!isShowModal) getTableData();
  }, [isShowModal]);

  const handleMenuItemClick = ({ withdrawalId, action }) => {
    const msg =
      action === withdrawalConstants?.ACCEPT
        ? `${t('ACCEPT_WITHDRAWAL_MSG')} ${getCustomerName()}`
        : `${t('REJECT_WITHDRAWAL_MSG')} ${getCustomerName()}`;
    setModalMessage(msg);
    setActionType(
      action === withdrawalConstants?.ACCEPT
        ? withdrawalConstants?.ACCEPT
        : withdrawalConstants?.REJECT
    );
    setIsShowModal(true);
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsShowModal(false);
  };

  const handleConfirm = ({ data, action }) => {
    if (action === withdrawalConstants.ACCEPT_STATUS) {
      acceptRejectWithdrawalDetails({
        id: data?.id,
        bankReferenceNumber: data?.bankReferenceNumber,
        status: withdrawalConstants.ACCEPT_STATUS,
      });
    } else {
      acceptRejectWithdrawalDetails({
        id: data?.id,
        remark: data?.remark,
        status: withdrawalConstants.REJECT_STATUS,
      });
    }
  };

  return (
    <Box marginX="8px" marginY={'-26px'}>
      <Header
        headingStyle={heading}
        onClick={() => navigate(`${ENDPOINTS.WITHDRAWALS}`)}
      >
        {t('WITHDRAWAL_REQUEST_STATUS')}
      </Header>
      <Box
        component={'form'}
        width={width}
        onSubmit={() => {}}
        minHeight={minHeight}
        paddingX={'32px'}
        paddingTop={'22px'}
        sx={paperStyle}
      >
        {loading ? (
          <Box sx={circularProgressBarPosition}>
            <CircularProgress sx={circularProgressBarStyle} />
          </Box>
        ) : (
          <>
            {isShowModal && (
              <AlertDialog
                open={isShowModal}
                handleClose={handleModalClose}
                handleConfirm={handleConfirm}
                actionType={actionType}
                title={t('WITHDRAWAL_VERIFICATION')}
                description={t(ModalMessage)}
                withdrawalId={withdrawalId}
                ModalMessage
              />
            )}
            <Box sx={cardsStyle}>
              <InformationBox
                title={t('CUSTOMER_DETAILS')}
                singleColumn
                cardInformation={{
                  [t('FIRST_NAME')]: withdrawalData?.firstName || t(`${NA}`),
                  [t('MIDDLE_NAME')]: withdrawalData?.middleName || t(`${NA}`),
                  [t('LAST_NAME')]: withdrawalData?.lastName || t(`${NA}`),
                  [t('EMAIL')]: withdrawalData?.email || t(`${NA}`),
                  [t('PHONE_NUMBER')]:
                    `+${[withdrawalData?.mobileCode, withdrawalData?.phoneNo]
                      .filter(Boolean)
                      .join('-')}` || t(`${NA}`),
                }}
              />
              <InformationBox
                title={t('BANK_DETAILS')}
                singleColumn
                cardInformation={{
                  [t('BANK_NAME')]: withdrawalData?.accountName || t(`${NA}`),
                  [t('BANK_ADDRESS')]:
                    withdrawalData?.bankAddress || t(`${NA}`),
                  [t('BANK_REFERENCE_NUMBER')]:
                    withdrawalData?.bankReferenceNo || t(`${NA}`),
                  [t('IBAN')]: withdrawalData?.iban || t(`${NA}`),
                }}
              />
              <InformationBox
                title={t('WITHDRAWAL_DETAILS')}
                singleColumn
                cardInformation={{
                  [t('WALLET_BALANCE')]:
                    withdrawalData?.walletBalance || t(`${NA}`),
                  [t('WITHDRAWAL_AMOUNT')]:
                    withdrawalData?.withdrawalAmount || t(`${NA}`),
                  [t('NET_AMOUNT')]: withdrawalData?.amount || t(`${NA}`),
                  [t('CHARGES')]: withdrawalData?.charges || t(`${NA}`),
                  [t('WITHDRAWAL_REASON')]:
                    withdrawalData?.userRemarks || t(`${NA}`),
                  [t('STATUS')]: withdrawalData?.status || t(`${NA}`),
                }}
              />
            </Box>
            {withdrawalData?.status !== withdrawalConstants.ACCEPT_STATUS &&
              withdrawalData?.status !== withdrawalConstants?.REJECT_STATUS && (
                <Box sx={endFormStyles}>
                  <CancelButton
                    onClick={() => navigate(`${ENDPOINTS.WITHDRAWALS}`)}
                  >
                    {t('CANCEL')}
                  </CancelButton>
                  <RejectButton
                    onClick={() =>
                      handleMenuItemClick({
                        withdrawalId: withdrawalId,
                        action: withdrawalConstants.REJECT,
                      })
                    }
                  >
                    {t('REJECT')}
                  </RejectButton>
                  <SubmitButton
                    variant="container"
                    onClick={() =>
                      handleMenuItemClick({
                        withdrawalId: withdrawalId,
                        action: withdrawalConstants.ACCEPT,
                      })
                    }
                  >
                    {t('APPROVE')}
                  </SubmitButton>
                </Box>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default WithdrawalDetails;
