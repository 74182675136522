import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getAdminLogs = (query) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.ADMIN_LOGS,
    query,
    method: METHODS.GET,
    privateURL: true,
  });
};
