import React from 'react';
import { Pagination as MUIPagination } from '@mui/material';
import PaginationItem from '@mui/material/PaginationItem';

const Pagination = ({ page, count, onChange }) => {
  return (
    <MUIPagination
      count={count}
      page={page}
      onChange={onChange}
      renderItem={(item) => {
        if (['start-ellipsis', 'end-ellipsis', 'page'].includes(item.type)) {
          return null;
        }
        return <PaginationItem {...item} />;
      }}
    />
  );
};

export default Pagination;
