import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// layouts
import BankTransfers from './pages/Bank Transfers/BankTransfers.js';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import UserPage from './pages/UserPage/UserPage.js';
import LoginPage from './pages/LoginPage';
import ForgetPassword from './pages/ForgetPassword';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/Dashboard/DashboardAppPage';
import ENDPOINTS from './constants/endpoints';
import ResetPassword from './pages/ResetPassword';
import VerifyOtpPage from './pages/VerifyOtpPage';
import RoleManagement from './pages/RoleManagement';
import AdminManagement from './pages/AdminManagement';
import RoleForm from './sections/@dashboard/role/role-form/index.js';
import { AuthGuard } from './components/auth-gaurd/AuthGuard.js';
import WarehouseForm from './pages/WarehouseForm.js';
import Warehouse from './pages/Warehouse.js';
import AdminForm from './sections/@dashboard/admin-management/admin-form/index.js';
import { AddUser } from './components/add-user';
import KycManagement from './pages/kyc';
import KycVerification from './pages/kyc/KycVerification';
import KybManagement from './pages/kyb/KybList.js';
import { KybVerification } from './pages/kyb/KybVerification.js';
import TransactionManagement from './pages/TransactionManagement/TransactionManagement';
import TransactionDetailsPage from './pages/TransactionManagement/TransactionDetailsPage';
import AddProduct from './pages/add-edit-product/AddProduct';
import ProductManagementPage from './pages/product/ProductManagement.js';
import OrderDetails from './pages/order-details-page/order-details';
import { OrderListing } from './pages/Order/OrderListing.js';
import { ReportListing } from './pages/ReportManagement/ReportManagement.js';
import AccountDelete from './pages/account-delete/Account-delete';
import AccountDeleteVerification from './pages/account-delete/account-delete-verification';
import TransferListing from './pages/transfer-gold-silver/TransferListing';
import TransaferDetails from './pages/transfer-gold-silver/Transfer-details';
import AccountManager from './pages/account-manager/AccountManager';
import AccountManagerForm from './sections/@dashboard/admin-management/account-manager-form/AccountManagerForm';
import AnnouncemenetPage from './pages/announcement/AnnouncementPage';
import AnnouncementForm from './sections/@dashboard/admin-management/announcement-form/AnnouncementForm';
import TicketManagement from './pages/TicketManagement/TicketManagement';
import TicketDetails from './pages/TicketManagement/TicketDetails';
import CityManagement from './pages/CityManagement/CityManagement.js';
import AddNewCityForm from './pages/CityManagement/AddNewCityForm.js';
import WithdrawalRequest from './pages/Withdrawal Requests/WithdrawalRequest.js';
import WithdrawalDetails from './pages/Withdrawal Requests/WithdrawalDetails.js';
import GiftSettings from './pages/GiftSettings/GiftSettings.js';
import AddNewSettings from './pages/GiftSettings/AddNewSettings.js';
import ThirdPartyClientListing from './pages/ThirdPartyClient/ThirdPartyClientListing.js';
import AdminLogsPage from './pages/AdminLogs/AdminLogsPage';
import TradeList from './pages/trades/TradeList.js';
import TradeListDetail from './pages/trades/TradeListDetail.js';
import TradeOrder from './pages/trades/TradeOrder.js';
import TradeOrderDetail from './pages/trades/TradeOrderDetail.js';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: ENDPOINTS.DASHBOARD,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'role', element: <RoleManagement /> },
        { path: 'admin', element: <AdminManagement /> },
        { path: ENDPOINTS.ROLE_FORM, element: <RoleForm /> },
        { path: ENDPOINTS.ADMIN_FORM, element: <AdminForm /> },
        {
          path: ENDPOINTS.ACCOUNT_MANAGER_FORM,
          element: <AccountManagerForm />,
        },
        {
          path: ENDPOINTS.ANNOUNCEMENT_FORM,
          element: <AnnouncementForm />,
        },
        { path: ENDPOINTS.KYC_MANAGEMENT, element: <KycManagement /> },
        { path: ENDPOINTS.KYC_VERIFICATION, element: <KycVerification /> },
        { path: ENDPOINTS.KYB_MANAGEMENT, element: <KybManagement /> },
        { path: ENDPOINTS.KYB_VERIFICATION, element: <KybVerification /> },
        { path: ENDPOINTS.ORDER_DETAILS, element: <OrderDetails /> },
        { path: ENDPOINTS.TRANSFER_LIST, element: <TransferListing /> },
        { path: ENDPOINTS.ACCOUNT_MANAGEMENT, element: <AccountManager /> },
        { path: ENDPOINTS.ANNOUNCEMENT_LIST, element: <AnnouncemenetPage /> },
        {
          path: ENDPOINTS.TICKET_MANAGEMENT,
          element: <TicketManagement />,
        },
        {
          path: ENDPOINTS.TICKET_MANAGEMENT_DETAILS,
          element: <TicketDetails />,
        },
        {
          path: ENDPOINTS.TRANSFER_LIST_DETAILS,
          element: <TransaferDetails />,
        },

        {
          path: ENDPOINTS.TRANSACTION_MANAGEMENT,
          element: <TransactionManagement />,
        },
        {
          path: ENDPOINTS.WALLET_TRANSACTION_DETAILS,
          element: <TransactionDetailsPage />,
        },
        {
          path: ENDPOINTS.PRODUCTS,
          element: <ProductManagementPage />,
        },
        {
          path: ENDPOINTS.ADDPRODUCT,
          element: <AddProduct />,
        },
        {
          path: ENDPOINTS.EDITPRODUCT,
          element: <AddProduct />,
        },
        {
          path: ENDPOINTS.ORDER_MANAGEMENT,
          element: <OrderListing />,
        },
        {
          path: ENDPOINTS.ADMIN_LOGS,
          element: <AdminLogsPage />,
        },
        {
          path: ENDPOINTS.VIEW_USER,
          element: <AddUser viewOnly />,
        },
        {
          path: ENDPOINTS.EDIT_USER,
          element: <AddUser />,
        },
        {
          path: ENDPOINTS.REPORT,
          element: <ReportListing />,
        },

        {
          path: ENDPOINTS.ACCOUNT_LIST,
          element: <AccountDelete />,
        },

        {
          path: ENDPOINTS.ACCOUNT_DELETE_DETAILS,
          element: <AccountDeleteVerification />,
        },
        {
          path: ENDPOINTS.WAREHOUSE,
          element: <Outlet />,
          children: [
            { path: '', element: <Warehouse /> },
            { path: ENDPOINTS.FORM, element: <WarehouseForm /> },
          ],
        },
        {
          path: ENDPOINTS.CITY_MANAGEMENT,
          element: <CityManagement />,
        },
        {
          path: ENDPOINTS.ADD_NEW_CITY,
          element: <AddNewCityForm />,
        },
        {
          path: ENDPOINTS.WITHDRAWALS,
          element: <WithdrawalRequest />,
        },
        {
          path: ENDPOINTS.BANK_TRANSFERS,
          element: <BankTransfers />,
        },
        {
          path: ENDPOINTS.WITHDRAWAL_DETAILS,
          element: <WithdrawalDetails width="100%" minHeight="460px" />,
        },
        {
          path: ENDPOINTS.GIFT_SETTINGS,
          element: <GiftSettings />,
        },
        {
          path: ENDPOINTS.ADD_NEW_SETTINGS,
          element: <AddNewSettings />,
        },
        {
          path: ENDPOINTS.UPDATE_SETTINGS,
          element: <AddNewSettings />,
        },
        {
          path: ENDPOINTS.THIRD_PARTY_CLIENTS_LISTING,
          element: <ThirdPartyClientListing />,
        },
        {
          path: ENDPOINTS.TRADE_LIST,
          element: <TradeList />,
        },
        {
          path: ENDPOINTS.TRADE_LIST_DETAILS,
          element: <TradeListDetail />,
        },
        {
          path: ENDPOINTS.TRADE_ORDER,
          element: <TradeOrder />,
        },
        {
          path: ENDPOINTS.TRADE_ORDER_VIEW,
          element: <TradeOrderDetail />,
        },
      ],
    },
    {
      path: ENDPOINTS.DASHBOARD,
      element: (
        <AuthGuard>
          <DashboardLayout contentInFullArea={true} />
        </AuthGuard>
      ),
      children: [],
    },
    {
      path: ENDPOINTS.LOGIN,
      element: (
        <AuthGuard>
          <LoginPage />
        </AuthGuard>
      ),
    },
    {
      path: ENDPOINTS.FORGET_PASSWORD,
      element: <ForgetPassword />,
    },
    {
      path: ENDPOINTS.RESET_PASSWORD,
      element: <ResetPassword />,
    },
    {
      path: ENDPOINTS.OPT_VERIFICATION,
      element: <VerifyOtpPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
