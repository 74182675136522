import { combineReducers } from 'redux';
import roleListsSlice, {
  resetRoleList,
  roleListSliceName,
} from '../slices/roleLists.slice';
import adminListSlice, {
  adminListSliceName,
  resetAdminList,
} from '../slices/adminList.slice';
import adminLogsSlice, {
  adminLogsSliceName,
  resetAdminLogs,
} from '../slices/adminLogs.slice';

import kycListSlice, {
  kycListSliceName,
  resetKycList,
} from '../slices/kycList.slice';
// Import the kyb slice
import kybListReducer, {
  kybListSliceName,
  resetKybList,
} from '../slices/kybList.slice';

const rootReducer = combineReducers({
  [roleListSliceName]: roleListsSlice,
  [adminListSliceName]: adminListSlice,
  [kycListSliceName]: kycListSlice,
  [adminLogsSliceName]: adminLogsSlice,
  [kybListSliceName]: kybListReducer, // Add this line
});

export const resetData = (dispatch) => {
  const resetActions = [
    resetRoleList,
    resetAdminList,
    resetKycList,
    resetKybList,
    resetAdminLogs,
  ];
  resetActions.forEach((action) => dispatch(action()));
};

export default rootReducer;
