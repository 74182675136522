import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

// Common headers and config
const commonConfig = {
  headers: {},
  privateURL: true,
  paymentUrl: true,
};

// Error messages
const ERROR_MESSAGES = {
  ID_REQUIRED: 'Trade ID is required',
  DATA_REQUIRED: 'Trade data is required',
  INVALID_TYPE: 'Invalid trade type',
  QUERY_REQUIRED: 'Query parameters are required',
};

// Validate required parameters
const validateParams = (params = {}, requiredFields = []) => {
  requiredFields.forEach((field) => {
    if (!params[field]) {
      throw new Error(`${field} is required`);
    }
  });
};

export const tradeService = {
  // List endpoints
  getTradeListOpen: (query) => {
    validateParams({ query }, ['query']);
    return apiRequest({
      ...commonConfig,
      endpoint: API_ENDPOINTS.GET_TRADE_LIST_OPEN,
      method: METHODS.GET,
      query,
    });
  },

  getTradeListClose: (query) => {
    validateParams({ query }, ['query']);
    return apiRequest({
      ...commonConfig,
      endpoint: API_ENDPOINTS.GET_TRADE_LIST_CLOSE,
      method: METHODS.GET,
      query,
    });
  },

  getTradeOrderOpen: (query) => {
    validateParams({ query }, ['query']);
    return apiRequest({
      ...commonConfig,
      endpoint: API_ENDPOINTS.GET_TRADE_ORDER_OPEN,
      method: METHODS.GET,
      query,
    });
  },

  getTradeOrderClose: (query) => {
    validateParams({ query }, ['query']);
    return apiRequest({
      ...commonConfig,
      endpoint: API_ENDPOINTS.GET_TRADE_ORDER_CLOSE,
      method: METHODS.GET,
      query,
    });
  },

  // Details endpoints
  getTradeById: async (id) => {
    validateParams({ id }, ['id']);

    try {
      // First try to get as position
      const response = await apiRequest({
        ...commonConfig,
        endpoint: `${API_ENDPOINTS.UPDATE_TRADE_POSITION}/${id}`,
        method: METHODS.GET,
      });

      if (response?.data) {
        return response;
      }
    } catch (error) {
      // If position not found, try as order
      return apiRequest({
        ...commonConfig,
        endpoint: `${API_ENDPOINTS.UPDATE_TRADE_POSITION}/${id}`,
        method: METHODS.GET,
      });
    }
  },

  getOrderById: async (id) => {
    validateParams({ id }, ['id']);

    try {
      // First try to get as position
      const response = await apiRequest({
        ...commonConfig,
        endpoint: `${API_ENDPOINTS.GET_ORDER_DETAILS}/${id}`,
        method: METHODS.GET,
      });

      if (response?.data) {
        return response;
      }
    } catch (error) {
      // If position not found, try as order
      return apiRequest({
        ...commonConfig,
        endpoint: `${API_ENDPOINTS.GET_ORDER_DETAILS}/${id}`,
        method: METHODS.GET,
      });
    }
  },

  // Update endpoints
  updateTradeOrder: (id, data) => {
    validateParams({ id, data }, ['id', 'data']);
    return apiRequest({
      ...commonConfig,
      endpoint: `${API_ENDPOINTS.GET_TRADE_DETAILS}/${id}`,
      method: METHODS.PUT,
      data,
    });
  },

  updateTradePosition: (id, data) => {
    validateParams({ id, data }, ['id', 'data']);
    return apiRequest({
      ...commonConfig,
      endpoint: `${API_ENDPOINTS.UPDATE_TRADE_POSITION}/${id}`,
      method: METHODS.PUT,
      data,
    });
  },

  // Position specific endpoints
  closeTradePosition: (id, data) => {
    validateParams({ id, data }, ['id', 'data']);
    return apiRequest({
      ...commonConfig,
      endpoint: `${API_ENDPOINTS.CLOSE_TRADE_POSITION}/${id}`,
      method: METHODS.POST,
      data,
    });
  },

  addTradePosition: (data) => {
    validateParams({ data }, ['data']);
    return apiRequest({
      ...commonConfig,
      endpoint: API_ENDPOINTS.ADD_TRADE_POSITIONS,
      method: METHODS.POST,
      data,
    });
  },

  // Helper methods
  getTradeType: (tradeData) => {
    if (!tradeData) throw new Error(ERROR_MESSAGES.DATA_REQUIRED);
    return tradeData.positionNo ? 'position' : 'order';
  },

  // Generic update method
  updateTrade: async (id, data) => {
    validateParams({ id, data }, ['id', 'data']);

    try {
      const type = tradeService.getTradeType(data);

      const updateMethod =
        type === 'position'
          ? tradeService.updateTradePosition
          : tradeService.updateTradeOrder;

      return await updateMethod(id, data);
    } catch (error) {
      console.error('Error updating trade:', error);
      throw error;
    }
  },

  // Format data for API
  formatTradeData: (data) => {
    if (!data) throw new Error(ERROR_MESSAGES.DATA_REQUIRED);

    return {
      ...data,
      unitOpeningRate: parseFloat(data.unitOpeningRate),
      unitClosingRate: parseFloat(data.unitClosingRate),
      totalOpeningPrice: parseFloat(data.totalOpeningPrice),
      totalClosingPrice: parseFloat(data.totalClosingPrice),
      profitAmount: parseFloat(data.profitAmount),
    };
  },
};

export default tradeService;
