import { createSlice } from '@reduxjs/toolkit';
import { getAdminLogs as getAdminLogsApi } from '../../api/adminLogs';
import asyncActionCreator from '../../common/utils/asyncActionCreator';

export const adminLogsSliceName = 'adminLogs';

export const getAdminLog = asyncActionCreator(
  `${adminLogsSliceName}/list`,
  getAdminLogsApi,
  {
    doNotNotify: true,
    doNotLoad: true,
  }
);

const initialState = {
  loading: true,
  error: false,
  data: [],
  totalList: 0,
  limit: 10,
};

const getAdminLogsSlice = createSlice({
  name: adminLogsSliceName,
  initialState,
  reducers: {
    resetAdminLogs: (state) => {
      state.data = {
        ...initialState.data,
        list: { ...initialState.data },
      };
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.totalList = initialState.totalList;
      state.limit = initialState.limit;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAdminLog.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAdminLog.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.data = action.payload.data || [];
        state.totalList = action.payload.totalCount;
        state.limit = action.payload.limit;
      })
      .addCase(getAdminLog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetAdminLogs } = getAdminLogsSlice.actions;

export default getAdminLogsSlice.reducer;
