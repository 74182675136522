/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import { useCallback, useEffect, useState } from 'react';
// @mui
import { Card, Stack, Container, Typography, Box } from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar/Scrollbar';
import { getAdminLogs } from '../../api/adminLogs';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import Pagination from '../../components/pagination';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../components/search-bar';
import { debounce } from 'lodash';
import LogDetails from './LogDetails';
// ---------------------------------------------------------------------

const CLICK_EVENT_KEYS = {
  LOG_DETAILS: 'LOG_DETAILS',
};

const getDebouncedSearchFunction = (getAdminLogsData) => {
  return debounce((searchTerm) => {
    getAdminLogsData(searchTerm);
  }, 500);
};

const MAX_LENGTH = 30;

export default function AdminLogsPage() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  // For Pagination
  //========================================================
  const [logData, setLogData] = useState();
  const [loading, setLoading] = useState(false);
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(10);
  const [open, setOpen] = useState(false);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  //========================================================
  useEffect(() => {
    getAdminLogsData();
  }, []);

  const getAdminLogsData = async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await getAdminLogs({
        pageLimit: limit,
        pageSkip: page - 1,
      });
      setLogData(data.data);
      setLimit(data.pageLimit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching report data:', error);
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getAdminLogsData(searchTerm, value);
  };

  const handleModalOpen = () => {
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
  };

  const handleClickEvent = (key, data = null) => {
    switch (key) {
      case CLICK_EVENT_KEYS.LOG_DETAILS:
        handleModalOpen();
        break;
    }
  };

  const debouncedFetchAdminLogs = useCallback(
    getDebouncedSearchFunction(getAdminLogsData),
    [getAdminLogsData]
  );

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    debouncedFetchAdminLogs(newSearchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    debouncedFetchAdminLogs('');
  };

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={'#A5A4A2'}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              handleClickEvent(CLICK_EVENT_KEYS.LOG_DETAILS)
            }
          >
            {t('CLICK_FOR_DETAILS')}
          </Typography>
        </Box>
        <LogDetails
          open={open}
          onClose={handleModalClose}
          data={rowItemData}
        />
      </>
    );
  };

  const transformedData = logData?.map((log) => {
    return {
      id: log.id,
      action: log.action,
      resource: log.resource,
      resourceId: log.resourceId,
      description: () => <ButtonsForTable rowItemData={log.description} />,
      ipAddress: log.ipAddress,
      createdAt: log.createdAt,
      adminEmail: log.adminEmail,
    };
  });

  const logsColumns = [
    { key: 'id', title: t('TH_LOGS_ID') },
    { key: 'action', title: t('TH_LOGS_ACTION') },
    { key: 'resource', title: t('TH_LOGS_RESOURCE') },
    { key: 'resourceId', title: t('TH_LOGS_RESOURCE_ID') },
    { key: 'description', title: t('TH_LOGS_DESCRIPTION'), isComponent: true },
    { key: 'ipAddress', title: t('TH_LOGS_IPADDRESS') },
    { key: 'createdAt', title: t('TH_LOGS_CREATED_AT') },
    { key: 'adminEmail', title: t('TH_LOGS_ADMIN_EMAIL') },
  ];

  const resourceName = {
    singular: 'log',
    plural: 'logs',
  };
  return (
    <Box>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'27px'}
        >
          <Typography variant="h4" gutterBottom paddingTop={'7px'}>
            {t('ADMIN_LOGS')}
          </Typography>
        </Stack>

        <Card sx={{ boxShadow: 'none' }}>
          <Scrollbar>
            <Card>
              <Box padding="8px" borderBottom="1px solid #E3E3E3">
                <Stack direction={'row'} justifyContent={'end'} gap={'10px'}>
                  <SearchBar
                    onChange={handleSearchChange}
                    value={searchTerm}
                    onClear={handleClearSearch}
                    placeholder={t('Search')}
                    maxlength={MAX_LENGTH}
                  />
                </Stack>
              </Box>
              <CommonTable
                resourceName={resourceName}
                data={transformedData}
                columns={logsColumns}
                isCheckBox={false}
                loading={loading}
                emptyTableText={t('NO_LOGS_FOUND')}
                onSortButtonClick={() => {}}
              />
            </Card>
          </Scrollbar>
          <Box
            display="flex"
            justifyContent={'flex-end'}
            sx={userPageStyles.paginationContainerSx}
          >
            <Typography
              color={'#C3C3C3'}
              fontSize={12}
              marginY={'6px'}
              fontFamily={'Inter'}
              fontWeight={'500'}
            >
              {t('PG_SHOWING')}
              <Typography
                component={'span'}
                fontSize={12}
                color={'#777673'}
                fontFamily={'Inter'}
                fontWeight={'500'}
                marginX={'2px'}
              >
                {Math.min(startIndex + 1, totalList)}-
                {Math.min(endIndex, totalList)}
              </Typography>
              {t('PG_FROM')}
              <Typography
                component={'span'}
                fontSize={12}
                color={'#777673'}
                fontFamily={'Inter'}
                fontWeight={'500'}
                marginX={'2px'}
              >
                {totalList}
              </Typography>
              {t('PG_RESULTS')}
            </Typography>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Card>
      </Container>
    </Box>
  );
}
