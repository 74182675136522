import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LanguageContext } from '../../../../globalContext/LanguageSwitcher';

import {
  successToaster,
  errorToaster,
  getModulePerm,
} from './../../../../utils/helperUtility';

import AuthInput from '../../../../components/auth-input';
import PhoneNumber from '../../../../components/phone-number';
import { styled } from '@mui/material/styles';
import LeftChevronIcon from '../../../../assets/icons/left_chevron.svg';
import {
  createAdmin,
  getAdminDetails,
  getRolesList,
  getCountriesList,
  editAdmin,
} from '../../../../api/adminManagement';

import { warehouseList } from '../../../../api/warehouse';

import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../../auth/login/LoginForm.style';
import { SelectComponent } from '../../../../components/add-user/SelectComponent';

import { addAdminFormSchema } from '../../../../schema/addAdmin';
import ENDPOINTS from '../../../../constants/endpoints';
import { flag } from '../../../../common/constants/AppConstants';
import { SelectComponentMultiple } from '../../../../components/add-user/SelectComponentMultiple';
import { MODULE_CONST } from '../../../../common/constants/ModuleConstant';
import RadioInput from '../../../../components/radio-input';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: '#E3CA7E',
  color: '#000000',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: '#E3CA7E',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#F4F3F3',
  color: '#3B3B3B',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: '#F4F3F3',
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
  };
});

export default function AdminForm({ isOpen, onClose, ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('CREATE_ADMIN');
  const [selecteduserRole, setSelecteduserRole] = useState([]);
  const [wareHouseList, setWareHouseList] = useState([]);

  const [roleList, setRoleList] = useState([]);
  const [countries, setCountries] = useState([]);
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    userRoles: [],
    isActive: true,
    fkWarehouseId: '',
  });

  const handleRadioValueChange = (value) => {
    formik.handleChange({
      target: {
        name: 'isActive',
        value: value,
      },
    });
  };
  const adminId = () => {
    return query.get('adminId');
  };
  const isViewForm = () => {
    return !!adminId() && !!query.get('view');
  };
  const isEditForm = () => {
    return !!adminId() && !isViewForm();
  };

  const getWarehouseList = async () => {
    const { data } = await warehouseList({
      pageSkip: 0,
    });
    setWareHouseList(data.data);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      if (isViewForm() || isEditForm()) {
        setPageTitle('ADMIN_DETAILS');
      }
      const response = await getRolesList();
      const filterActiveRole = response.data.data.filter((item) => {
        return item.isActive;
      });
      setRoleList(filterActiveRole);
      const resCountries = await getCountriesList();

      const modifiedCountry = resCountries.data.data.map((country) => {
        return {
          ...country,
          id: country.id,
        };
      });
      setCountries(modifiedCountry);
      if (!adminId()) {
        setInitialValues((prev) => ({
          ...prev,
          countryCode: modifiedCountry[0]?.id,
        }));
      }
      if (adminId()) {
        getAdminDetails(adminId())
          .then(({ data }) => {
            const {
              firstName,
              middleName,
              lastName,
              email,
              phoneNo,
              role,
              mobileCode,
              isActive,
              fkWarehouseId,
            } = data.data;
            const receivedRoles = role.map((list) => {
              return list.fkRolesId;
            });
            const selectedCountries = modifiedCountry.find((data) => {
              return data.mobileCode === mobileCode;
            });

            const receivedValue = {
              firstName: firstName || '',
              middleName: middleName || '',
              lastName: lastName || '',
              email: email || '',
              phoneNumber: phoneNo || '',
              userRoles: receivedRoles || [],
              countryCode: selectedCountries?.id || '',
              isActive: isActive || false,
              fkWarehouseId: fkWarehouseId || '',
            };
            setInitialValues(receivedValue);
            setSelecteduserRole(receivedRoles);
          })
          .catch((err) => {
            errorToaster(err.data.message, language);
          });
      }
    };
    fetchAllData();
    getWarehouseList();
  }, []);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addAdminFormSchema,
    onSubmit: async (values) => {
      isEditForm() ? submitEditAdmin(values) : submitCreateAdmin(values);
    },
  });

  const submitCreateAdmin = async (values) => {
    try {
      setLoading(true);
      let {
        firstName,
        middleName,
        lastName,
        email,
        phoneNumber,
        userRoles,
        countryCode,
        isActive,
        fkWarehouseId,
      } = values;
      const payload = {
        firstName,
        middleName,
        lastName,
        email,
        rolesId: userRoles,
        phoneNo: phoneNumber,
        fkCountriesId: countryCode,
        fkWarehouseId,
      };
      const response = await createAdmin({ ...payload });
      if (response.data.isSuccess) {
        successToaster(t('ADMIN_ADDED_SUCCESS'), language);
        navigate(ENDPOINTS.ADMIN_MANAGEMENT);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  const submitEditAdmin = async (values) => {
    try {
      setLoading(true);
      let {
        firstName,
        middleName,
        lastName,
        phoneNumber,
        userRoles,
        isActive,
        fkWarehouseId,
      } = values;
      const payload = {
        firstName,
        middleName,
        lastName,
        isActive,
        rolesId: userRoles,
        phoneNo: phoneNumber,
        fkWarehouseId,
      };
      const response = await editAdmin(payload, adminId());
      if (response.data.isSuccess) {
        successToaster(t('ADMIN_UPDATE_SUCCESS'), language);
        navigate(ENDPOINTS.ADMIN_MANAGEMENT);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelButton = () => {
    navigate(ENDPOINTS.ADMIN_MANAGEMENT);
  };

  const handleSubmit = (event) => {
    if (isViewForm()) {
      navigate(`${ENDPOINTS.ADMIN_FORM}?adminId=${adminId()}`);
      return;
    }
    formik.submitForm(event);
  };

  const currentModulePerm = getModulePerm(MODULE_CONST.ADMIN_MANAGEMENT);

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.ADMIN_MANAGEMENT)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{t(pageTitle)}</FormTitle>
      </div>
      <FormContainer>
        <div className="row">
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="firstName"
            label={t('FIRST_NAME')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('firstName'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('FIRST_NAME')}
            name="firstName"
            id="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('firstName')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('firstName') && t(formik.errors.firstName)}
          />
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="middleName"
            label={t('MIDDLE_NAME')}
            disabled={isViewForm()}
            sx={textInputField(isFieldError('middleName'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('MIDDLE_NAME')}
            name="middleName"
            id="middleName"
            onChange={formik.handleChange}
            value={formik.values.middleName}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('middleName')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('middleName') && t(formik.errors.middleName)
            }
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="lastName"
            label={t('LAST_NAME')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('lastName'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('LAST_NAME')}
            name="lastName"
            id="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('lastName')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('lastName') && t(formik.errors.lastName)}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="email"
            label={t('EMAIL_TEXT')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('email'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('EMAIL_TEXT')}
            name="email"
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('email')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('email') && t(formik.errors.email)}
          />
          <PhoneNumber
            className="col-12 col-md-4"
            placeholderForCountry="Country code"
            fieldName="countryCode"
            multiSelect={false}
            labelName={t('PHONE_NUMBER')}
            options={countries}
            disabled={isViewForm() || isEditForm()}
            disableInput={isViewForm()}
            selectedOptions={formik.values.countryCode}
            formik={formik}
            errorText={
              isFieldError('countryCode') && t(formik.errors.countryCode)
            }
            htmlFor="phoneNumber"
            label={''}
            required={true}
            sx={textInputField(isFieldError('phoneNumber'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 15 }}
            placeholder={t('PHONE_NUMBER')}
            name="phoneNumber"
            id="phoneNumber"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('phoneNumber')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('phoneNumber') && t(formik.errors.phoneNumber)
            }
          />

          <div className="col-12 col-md-4">
            <RadioInput
              disabled={isViewForm()}
              parentLabel={t('STATUS')}
              required={true}
              value={formik.values.isActive}
              textFieldError={isFieldError('isActive')}
              helperText={isFieldError('isActive')}
              options={[
                { label: t('ACTIVE'), value: true },
                { label: t('INACTIVE'), value: false },
              ]}
              labelKey="isActive"
              handleChange={handleRadioValueChange}
            />
          </div>

          <SelectComponentMultiple
            className="col-12 col-md-4"
            placeholder={t('USER_ROLES')}
            fieldName="userRoles"
            multiSelect={true}
            labelName={t('USER_ROLES')}
            options={roleList}
            disabled={isViewForm()}
            selectedOptions={formik.values.userRoles}
            formik={formik}
            isFieldError={isFieldError('userRoles')}
            errorText={isFieldError('userRoles') && t(formik.errors.userRoles)}
          />

          <div className="col-12 col-md-4 mb-4">
            <SelectComponent
              fieldName={'fkWarehouseId'}
              formik={formik}
              labelName={t('WAREHOUSE')}
              placeholder={t('PLACEHOLDER_WAREHOUSE')}
              language={language}
              options={wareHouseList}
              value={formik.values.fkWarehouseId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isViewForm()}
              required={false}
            />
          </div>
        </div>
        <FormButtons>
          <CancelButton onClick={() => handleCancelButton()}>
            {t('CANCEL')}
          </CancelButton>

          {currentModulePerm.writeMode && (
            <SubmitButton
              type="submit"
              onClick={handleSubmit}
              disabled={!currentModulePerm.writeMode}
            >
              {t(
                !isEditForm()
                  ? isViewForm()
                    ? 'EDIT'
                    : 'CREATE_ADMIN'
                  : 'SAVE'
              )}
            </SubmitButton>
          )}
        </FormButtons>
      </FormContainer>
    </div>
  );
}
